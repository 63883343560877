import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Trans, useTranslation } from "react-i18next";

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";

import { retrunLanguage } from "../../utils/languageSelect";

import { paths } from "../../routes/paths";

import "./style.css";
import { addToCartScript } from "../../utils/conversionScripts";

export const DialogComponent = (props) => {
  const { i18n, t } = useTranslation("dialog");

  const {
    notDeliver,
    setNotDeliver,
    location,
    fromDetail,
    selectedItemToDeliver,
    hasItems,
    cartItems,
    restaurantName,
    resturantId,
    newText,
    home,
    updateText,
    menuCard,
    locationDeliver,
    sharedItemForCart,
    afterSelect,
    pickup,
  } = props;

  const token = localStorage?.getItem("token");

  const navigate = useNavigate();

  const handleClose = () => {
    setNotDeliver(false);
  };

  return (
    <Dialog
      open={notDeliver}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="not-delivered"
      sx={{
        overflow: "hidden",
        "& .MuiDialog-paper": {
          width: "327px",
          textAlign: "center",
          overflow: "visible",
          margin: "27px",
          scrollbarWidth: "none",

          "@media (max-width: 500px)": {
            width: "375px",
            margin: "30px",
          },

          "@media (max-width: 400px)": {
            width: "90%",
            margin: "25px",
          },
        },
      }}
    >
      <DialogTitle id="alert-dialog-title" className="dialog-title">
        <img src="/assets/img/exclaim.svg"></img>
      </DialogTitle>
      <DialogContent
        className="content"
        sx={{
          scrollbarWidth: "none",
        }}
      >
        <DialogContentText id="alert-dialog-description">
          {fromDetail ? (
            <div className="text-detail">
              <Trans
                i18nKey={t("restaurantNotDeliver")}
                values={{
                  restaurantName:
                    location?.state?.restaurantDetail !== undefined
                      ? location?.state?.restaurantDetail?.name_en
                      : localStorage.getItem("restaurantName") !== null
                      ? localStorage.getItem("restaurantName")
                      : restaurantName,
                  text: newText ? t("new") : t("current"),
                }}
              />
            </div>
          ) : (
            <div className="text-detail">
              <Trans
                i18nKey={pickup ? t("itemsNotPickup") : t("itemsNotDelivered")}
                values={{
                  text: updateText ? updateText : t("current"),
                }}
              />
            </div>
          )}

          {fromDetail ? (
            <>
              <div className="disp-flex">
                <Button
                  onClick={() => {
                    !home
                      ? navigate(paths.home)
                      : home && menuCard
                      ? navigate(paths.home)
                      : handleClose();
                  }}
                  className="btn change-button"
                >
                  <span>{t("changeRestaurant")}</span>
                </Button>

                <Button
                  onClick={() => {
                    if (fromDetail) {
                      addToCartScript(
                        [sharedItemForCart?.menuItemDetails?.data],
                        sharedItemForCart?.typeStatus,
                        sharedItemForCart?.ItemDataObject?.quantity,
                        restaurantName
                      );
                    }
                    if (locationDeliver !== undefined && token === null) {
                      navigate(paths.location, { state: sharedItemForCart });
                    } else {
                      navigate(paths.location, {
                        state: {
                          path: menuCard
                            ? "menu/restaurant"
                            : home && !menuCard
                            ? "/home"
                            : location.pathname,
                          restaurantName: home
                            ? restaurantName
                            : localStorage.getItem("restaurantName") !== null
                            ? localStorage.getItem("restaurantName")
                            : location?.state?.restaurantDetail?.page_title_en,
                          restaurant_id:
                            home || afterSelect
                              ? resturantId
                              : location?.state?.restaurant_id !== undefined
                              ? location?.state?.restaurant_id
                              : +location?.state?.resturantId,
                        },
                      });
                    }
                  }}
                  className={!home ? "btn change-button" : "btn back-button"}
                >
                  <span>{t("changeLocation")}</span>
                </Button>
              </div>

              {!home ? (
                <Button
                  color="primary"
                  autoFocus
                  className="btn back-button"
                  onClick={() => {
                    navigate(
                      `${paths.menu}/restaurant/${
                        location?.state?.resturantId === undefined
                          ? localStorage.getItem("restaurantID")
                          : +location.state.resturantId
                      }/delivery`
                    );

                    handleClose();
                  }}
                >
                  <span>{t("backToMenu")}</span>
                </Button>
              ) : null}
            </>
          ) : !hasItems ? (
            <SingleItemNotDelivered
              t={t}
              i18n={i18n}
              selectedItemToDeliver={selectedItemToDeliver}
              handleClose={handleClose}
            />
          ) : (
            <ListItemsNotDelivered
              handleClose={handleClose}
              cartItems={cartItems}
              navigate={navigate}
              i18n={i18n}
              t={t}
            />
          )}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

const ListItemsNotDelivered = (props) => {
  const { cartItems, handleClose, navigate, i18n, t } = props;

  return (
    <Box>
      <Box className="to-scroll">
        {cartItems?.map((item) => {
          const checkIfImageExists = () => {
            const img = new Image();
            img.src = item?.itemImage;

            if (img.complete) {
              return true;
            } else {
              img.onload = () => {
                return true;
              };

              img.onerror = () => {
                return false;
              };
            }
          };

          return (
            <Box key={item.id} className="items-not-delvered">
              {checkIfImageExists() === true ? (
                <div
                  className="items-image"
                  style={{
                    marginRight: retrunLanguage(i18n) ? "0px" : "19px",
                    marginLeft: retrunLanguage(i18n) ? "19px" : "0px",
                  }}
                >
                  <img src={item?.itemImage} alt={item.itemName} />
                </div>
              ) : (
                <Box
                  sx={{
                    width: 100,
                    height: 100,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "common.white",
                    borderRadius: "8px",
                  }}
                >
                  <ImageNotSupportedIcon fontSize="large"></ImageNotSupportedIcon>

                  <Typography variant="caption">{t("noImage")}</Typography>
                </Box>
              )}

              <div>
                <Typography className="item-heading" variant="h5">
                  {item.itemName}
                </Typography>
              </div>
            </Box>
          );
        })}
      </Box>

      <div className="btn-navigate">
        <Button
          className="common-st add-btn"
          onClick={() =>
            navigate(
              `${paths.menu}/restaurant/${localStorage.getItem(
                "restaurantID"
              )}/delivery`
            )
          }
        >
          {t("addMore")}
        </Button>

        <Button className="common-st go-to-btn" onClick={() => handleClose()}>
          {t("goToCart")}
        </Button>
      </div>
    </Box>
  );
};

const SingleItemNotDelivered = ({
  selectedItemToDeliver,
  handleClose,
  t,
  i18n,
}) => {
  const [imageExist, setImageExist] = useState(false);

  const checkIfImageExists = (url, callback) => {
    const img = new Image();
    img.src = url;

    if (img.complete) {
      callback(true);
    } else {
      img.onload = () => {
        callback(true);
      };

      img.onerror = () => {
        callback(false);
      };
    }
  };

  useEffect(() => {
    checkIfImageExists(selectedItemToDeliver?.image, (exists) => {
      if (exists) {
        setImageExist(false);
      } else {
        setImageExist(true);
      }
    });
  }, [selectedItemToDeliver]);

  return (
    <Box>
      <Box className="items-not-delvered">
        {!imageExist ? (
          <div className="items-image">
            <img
              src={selectedItemToDeliver?.image}
              alt={selectedItemToDeliver?.name_en}
            />
          </div>
        ) : (
          <Box
            sx={{
              width: 100,
              height: 100,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "common.white",
              borderRadius: "8px",
            }}
          >
            <ImageNotSupportedIcon fontSize="large"></ImageNotSupportedIcon>

            <Typography variant="caption">{t("noImage")}</Typography>
          </Box>
        )}

        <div>
          <Typography className="item-heading" variant="h5">
            {retrunLanguage(i18n)
              ? selectedItemToDeliver?.name_ar
              : selectedItemToDeliver?.name_en}
          </Typography>
        </div>
      </Box>

      <Button className="continue-btn" onClick={() => handleClose()}>
        {t("continue", { ns: "common" })}
      </Button>
    </Box>
  );
};
