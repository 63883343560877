import React from "react";

import "./style.css";

export const HomeAdress = (props) => {
  const { handleChange, address, blockErr, streetErr, houseNoErr, i18n, t } =
    props;

  return (
    <form className={`container-style px-3 ${i18n ? "input-change" : ""}`}>
      <div
        className={`inputwrapper ${blockErr ? "errorinputwrapper" : ""}${
          i18n ? "inputwrapper-ar" : "inputwrapper-en"
        }`}
        data-required={t("required")}
      >
        <input
          className={`form-style ${blockErr ? "error" : ""}`}
          type="text"
          name="Block"
          placeholder={t("block")}
          onChange={(e) => handleChange("block", e.target.value)}
          value={address.block}
        />
      </div>

      <div
        className={`inputwrapper ${streetErr ? "errorinputwrapper" : ""}${
          i18n ? "inputwrapper-ar" : "inputwrapper-en"
        }`}
        data-required={t("required")}
      >
        <input
          className={`form-style ${streetErr ? "error" : ""}`}
          type="text"
          name="Street"
          placeholder={t("street")}
          onChange={(e) => handleChange("street", e.target.value)}
          value={address.street}
        />
      </div>

      <input
        className="form-style"
        type="text"
        name="Avenue"
        placeholder={t("avenue")}
        onChange={(e) => handleChange("avenue", e.target.value)}
        value={address.avenue}
      />

      <div
        className={`inputwrapper ${houseNoErr ? "errorinputwrapper" : ""}${
          i18n ? "inputwrapper-ar" : "inputwrapper-en"
        }`}
        data-required={t("required")}
      >
        <input
          className={`form-style ${houseNoErr ? "error" : ""}`}
          type="text"
          name="House Number"
          placeholder={t("houseNumber")}
          onChange={(e) => handleChange("house_no", e.target.value)}
          value={address.house_no}
        />
      </div>

      <input
        className="form-style"
        type="text"
        name="Address Label"
        placeholder={t("addressLabel")}
        onChange={(e) => handleChange("address_label", e.target.value)}
        value={address.address_label}
      />

      <textarea
        className="form-style form-style-text-area"
        name="AdditionalDirection"
        id="AdditionalDirection"
        placeholder={t("additionalDir")}
        rows="4"
        onChange={(e) => handleChange("additional_direction", e.target.value)}
        value={address.additional_direction}
      ></textarea>
    </form>
  );
};
