import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";

// import { useQuery } from "@tanstack/react-query";

import BasicCard from "../../cards/index";

import ExpandedButton from "../expandableButton";

import { paths } from "../../../routes/paths";

import getAllRestaurants from "../../../api/home/getAllRestaurants";

import {
  getResturants,
  removeSelectedService,
  setSelectedService,
} from "../../../store/reducers/resturantsSlice";

import "./style.css";
import { selectServiceScript } from "../../../utils/conversionScripts";

const RestaurantsOrderTypeButton = (props) => {
  const { t } = useTranslation(["landingPage"]);

  const {
    setOpenAddressPopup,
    setToggleFilterClassNear,
    filterClassNear,
    filterSelectedNow,
    setFilterSelectedNow,
    goToRestaurantMenuPage,
    setRestaurantId,
    setRestaurantName,
    filterClassDelivery,
    setToggleFilterClassDelivery,
    selectedText,
    setSelectedText,
    typesState,
    setTypesState,
    selectedFilter,
    openSelectAddress,
  } = props;

  const token = localStorage.getItem("token");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ranOnceRef = useRef(false);
  /* Country to be refactored */
  // const country = useSelector((state) => state.auth.country);
  const [restaurantsData, setRestaurantsData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllRestaurants(3);

        // Assuming your API response is in the correct format, update the state with the data.
        setRestaurantsData(response.data);

        // Dispatch the data to the Redux store.
        dispatch(getResturants(response.data));
      } catch (error) {
        // Handle errors here
        console.error(`${t("errorFetching", { ns: "translation" })}`, error);
      }
    };
    if (!ranOnceRef.current) {
      fetchData();
      ranOnceRef.current = true;
    }
  }, [dispatch]);

  const data = [
    {
      iconSrc: "/assets/img/Dinein.svg",
      OrderType: `${t("dinein")}`,
      locations: `${t("dineInLocation")}`,
      where: `${t("nearMe")}`,
      id: 0,
    },
    {
      iconSrc: "/assets/img/Catering.svg",
      OrderType: `${t("catering")}`,
      locations: `${t("dineInLocation")}`,
      where: `${t("nearMe")}`,
      id: 1,
    },
    {
      iconSrc: "/assets/img/Delivery.svg",
      OrderType: `${t("delivery")}`,
      locations: `${t("availableForDelivery")}`,
      where: `${t("deliversToMe")}`,
      id: 2,
    },
    {
      iconSrc: "/assets/img/Pickup.svg",
      OrderType: `${t("pickup")}`,
      locations: `${t("availableForPickup")}`,
      where: `${t("nearMe")}`,
      id: 3,
    },
  ];

  const [clickedButtonIndex, setClickedButtonIndex] = useState(null);
  const [orderTypeFilter, setOrderTypeFilter] = useState();
  const [expanded, setToggleExpanded] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const parentDiv = document?.getElementById("parent-of-all");
  const parentIcon = document?.getElementsByClassName("inner-svg-container");
  const divs = parentDiv?.getElementsByClassName("restaurantCards");

  // Removing border and background color if Delivers to me is not selected
  const removeClasses = () => {
    // Remove border from restaurant image
    for (let i = 0; i < divs?.length; i++) {
      divs[i].classList.remove("delivery-true");
    }

    // Remove background color from icon on restaurant image
    for (let i = 0; i < parentIcon?.length; i++) {
      const icons = parentIcon[i].querySelectorAll(".icon-row");
      icons.forEach((icon) => {
        if (icon.id === "icon-row") {
          icon.classList.remove("delivers-image");
        }
      });
    }
  };

  // Removing border and background color if Available to deliver is not selected
  const removeBrownClasses = () => {
    // Remove border from restaurant image
    for (let i = 0; i < divs?.length; i++) {
      divs[i].classList.remove("add-brown-border");
    }

    // Remove background color from icon on restaurant image
    for (let i = 0; i < parentIcon?.length; i++) {
      const icons = parentIcon[i].querySelectorAll(".icon-row");
      icons.forEach((icon) => {
        if (icon.id === "icon-row") {
          icon.classList.remove("bg-brown");
        }
      });
    }
  };

  const handleOrderTypeFilter = (
    deliveryFilter,
    nearFilter,
    filterSelectedNow
  ) => {
    setOrderTypeFilter({
      delivery: deliveryFilter,
      near: nearFilter,
      selectedNow: filterSelectedNow,
    });
  };

  const handleButtonClickIndex = (index) => {
    if (index === clickedButtonIndex) {
      // If the same button is clicked again, remove the border
      setClickedButtonIndex(null);
    } else {
      // If a different button is clicked, set the border for that button
      setClickedButtonIndex(index);
    }
  };

  const handleFilterClick = (orderType) => {
    const shouldSetFilterSelectedNow =
      orderType === t("dineInLocation") ||
      orderType === t("availableForDelivery") ||
      orderType === t("availableForPickup") ||
      orderType === t("nearMe");

    selectServiceScript(selectedText, orderType);

    // Set filterSelectedNow if applicable
    if (!filterClassDelivery && shouldSetFilterSelectedNow) {
      setFilterSelectedNow(orderType);
    }

    if (!filterClassNear) {
      if (orderType === t("deliversToMe")) {
        setFilterSelectedNow(orderType);
        setOpenAddressPopup?.(true);
      }

      if (orderType === t("nearMe")) {
        setFilterSelectedNow(orderType);
        // if (selected === null) {
        if (token === null) {
          navigate(paths.location, {
            state: {
              path: "/home",
              filterSelectedNow: "pickup",
            },
          });
        }

        if (selectedText === t("pickup") && token !== null)
          openSelectAddress(true);
        // } else {
        //   navigate(paths.nearMe);
        // }
      }
    }

    // Determine if we should toggle filter classes
    const shouldToggleBoth =
      orderType === t("dineInLocation") ||
      orderType === t("availableForDelivery") ||
      orderType === t("availableForPickup");

    if (shouldToggleBoth) {
      setToggleFilterClassDelivery(!filterClassDelivery);
      setToggleFilterClassNear(!filterClassNear);
    } else {
      setToggleFilterClassNear(!filterClassNear);
      setToggleFilterClassDelivery(!filterClassDelivery);
    }
  };

  const handleClick = (selectedService, id) => {
    setSelectedId(id);
    if (!typesState[selectedService === t("dinein") ? t("dineinsm") : selectedService.toLowerCase()]) {
      setToggleExpanded(true);
    } else {
      setToggleExpanded(false);
    }

    setSelectedText(selectedService);

    if (selectedService === t("dinein")) {
      const removeSpace = t("dineinsm");
      dispatch(setSelectedService(removeSpace));
    } else {
      dispatch(setSelectedService(selectedService.toLowerCase()));
    }

    setToggleFilterClassDelivery(true);

    if (selectedService !== t("catering")) {
      if (selectedService === t("dinein")) {
        setTypesState({
          dinein: !typesState.dinein,
          catering: false,
          delivery: false,
          pickup: false,
        });
        // setTypesState({
        //   dinein: selectedService === "Dine In" && !typesState.dinein,
        //   catering: false,
        //   delivery: selectedService === "Delivery" && !typesState.delivery,
        //   pickup: selectedService === "Pickup" && !typesState.pickup,
        // });
        removeClasses();
        removeBrownClasses();
        setFilterSelectedNow(t("dineInLocation"));
      }

      if (selectedService === t("delivery")) {
        setTypesState({
          dinein: false,
          catering: false,
          delivery: !typesState.delivery,
          pickup: false,
        });
        setFilterSelectedNow(t("availableForDelivery"));
      }

      if (selectedService === t("pickup")) {
        setTypesState({
          dinein: false,
          catering: false,
          delivery: false,
          pickup: !typesState.pickup,
        });
        removeClasses();
        removeBrownClasses();
        setFilterSelectedNow(t("availableForPickup"));
      }

      if (!expanded) {
        // Set the delivery filter as active by default when expanding the button
        setToggleFilterClassDelivery(true);
        setToggleFilterClassNear(false);
        // setFilterSelectedNow(t("deliverysm"));
      }
    } else {
      setTypesState({
        dinein: false,
        catering: !typesState.catering,
        delivery: false,
        pickup: false,
      });
      removeClasses();
      removeBrownClasses();
    }

    handleButtonClickIndex(id);
  };

  useEffect(()=>{
    if (expanded) {
      selectedText !== t("catering")
        ? selectServiceScript(selectedText, filterSelectedNow)
        : selectServiceScript(selectedText);
    }
  },[expanded, selectedText])

  useEffect(() => {
    if (!typesState.delivery) {
      removeClasses();
      removeBrownClasses();
    }

    if (
      (typesState.dinein || typesState.delivery || typesState.pickup) &&
      filterClassNear
    ) {
      // setFilterSelectedNow("");
      setToggleFilterClassNear(false);
      removeBrownClasses();
      removeClasses();
    }

    if (
      !typesState.dinein &&
      !typesState.catering &&
      !typesState.delivery &&
      !typesState.pickup
    ) {
      dispatch(removeSelectedService());
      setToggleFilterClassNear(false);
      setToggleFilterClassDelivery(false);
    }
  }, [typesState]);

  return (
    <>
      <div className="restaurant-title">
        <h4>{t("heading")}</h4>
      </div>
      <div className="orderType-button-container">
        <ExpandedButton
          iconSrc={data[0].iconSrc}
          text={
            (!typesState.dinein &&
              !typesState.delivery &&
              !typesState.pickup) ||
            selectedText === t("catering")
              ? data[0].OrderType
              : ""
          }
          hideOther={selectedText === t("dinein")}
          otherText={t("dinein")}
          buttonOneText={data[0].locations}
          buttonTwoText={data[0].where}
          clicked={false}
          handleClick={handleClick}
          id={data[0].id}
          handleOrderTypeFilter={handleOrderTypeFilter}
          filterClassDelivery={filterClassDelivery}
          filterClassNear={filterClassNear}
          filterSelectedNow={filterSelectedNow}
          isButtonExpanded={typesState.dinein}
          selectedId={selectedId}
          customButtonExpandedClass={
            typesState.dinein ? "custom-button-expanded expanded-width" : ""
          }
          handleFilterClick={handleFilterClick}
          notIncludes={
            !typesState.dinein && (typesState.delivery || typesState.pickup)
              ? "shrink-expanded"
              : ""
          }
        />

        <ExpandedButton
          iconSrc={data[1].iconSrc}
          text={
            (!typesState.dinein &&
              !typesState.delivery &&
              !typesState.pickup) ||
            selectedText === t("catering")
              ? data[1].OrderType
              : ""
          }
          otherText={t("catering")}
          clicked={selectedId === 1 ? clickedButtonIndex === 1 : false}
          handleClick={handleClick}
          id={1}
          handleOrderTypeFilter={handleOrderTypeFilter}
          filterClassNear={filterClassNear}
          filterSelectedNow={filterSelectedNow}
          isButtonExpanded={false}
          selectedId={selectedId}
          handleFilterClick={handleFilterClick}
          typesState={typesState}
          customButtonExpandedClass={""}
          notIncludes={
            !typesState.catering &&
            (typesState.dinein || typesState.delivery || typesState.pickup)
              ? "shrink-expanded"
              : ""
          }
          filterClassDelivery={filterClassDelivery}
          selectedText={selectedText}
        />

        <ExpandedButton
          iconSrc={data[2].iconSrc}
          text={
            (!typesState.dinein &&
              !typesState.delivery &&
              !typesState.pickup) ||
            selectedText === t("catering")
              ? data[2].OrderType
              : ""
          }
          hideOther={selectedText === t("delivery")}
          otherText={t("delivery")}
          buttonOneText={data[2].locations}
          buttonTwoText={data[2].where}
          clicked={false}
          handleClick={handleClick}
          id={2}
          handleOrderTypeFilter={handleOrderTypeFilter}
          filterClassNear={filterClassNear}
          filterSelectedNow={filterSelectedNow}
          isButtonExpanded={typesState.delivery}
          selectedId={selectedId}
          customButtonExpandedClass={
            typesState.delivery ? "custom-button-expanded expanded-width" : ""
          }
          notIncludes={
            !typesState.delivery && (typesState.dinein || typesState.pickup)
              ? "shrink-expanded"
              : ""
          }
          handleFilterClick={handleFilterClick}
          filterClassDelivery={filterClassDelivery}
        />

        <ExpandedButton
          iconSrc={data[3].iconSrc}
          text={
            (!typesState.dinein &&
              !typesState.delivery &&
              !typesState.pickup) ||
            selectedText === t("catering")
              ? data[3].OrderType
              : ""
          }
          hideOther={selectedText === t("pickup")}
          otherText={t("pickup")}
          buttonOneText={data[3].locations}
          buttonTwoText={data[3].where}
          clicked={false}
          handleClick={handleClick}
          id={3}
          handleOrderTypeFilter={handleOrderTypeFilter}
          filterClassNear={filterClassNear}
          filterSelectedNow={filterSelectedNow}
          isButtonExpanded={typesState.pickup}
          selectedId={selectedId}
          customButtonExpandedClass={
            typesState.pickup ? "custom-button-expanded expanded-width" : ""
          }
          notIncludes={
            !typesState.pickup && (typesState.dinein || typesState.delivery)
              ? "shrink-expanded"
              : ""
          }
          handleFilterClick={handleFilterClick}
          filterClassDelivery={filterClassDelivery}
        />
      </div>
      {/* Pass the clickedButtonIndex to BasicCard */}
      {restaurantsData?.length > 0 && (
        <BasicCard
          clickedButtonIndex={clickedButtonIndex}
          orderTypeFilter={orderTypeFilter}
          restaurantsData={restaurantsData}
          goToRestaurantMenuPage={goToRestaurantMenuPage}
          selectedText={selectedText}
          typesState={typesState}
          selectedFilter={selectedFilter}
          filterSelectedNow={filterSelectedNow}
        />
      )}
    </>
  );
};

export default RestaurantsOrderTypeButton;
