import React from "react";

export const CateringIconDark = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
      width="23"
      height="15.5"
      viewBox="0 0 20.632 14"
    >
      <g id="catering_Icon">
        <path
          id="Path_50"
          data-name="Path 50"
          d="M20.3,10.678H18.941a8.635,8.635,0,0,0-8.29-8.588V.335a.335.335,0,1,0-.67,0V2.089a8.635,8.635,0,0,0-8.29,8.588H.335a.335.335,0,0,0,0,.67H1.69v.023A2.631,2.631,0,0,0,4.318,14H16.313a2.632,2.632,0,0,0,2.628-2.628v-.023H20.3a.335.335,0,0,0,0-.67M10.316,2.751a7.964,7.964,0,0,1,7.955,7.927H2.361a7.964,7.964,0,0,1,7.955-7.927m7.956,8.62a1.961,1.961,0,0,1-1.958,1.958H4.318A1.961,1.961,0,0,1,2.36,11.371v-.023H18.272Z"
          transform="translate(0 0)"
          fill="#323f4b"
        />
        <path
          id="Path_51"
          data-name="Path 51"
          d="M14.28,22.687a.335.335,0,0,0-.474.474l.976.976a.335.335,0,1,0,.474-.474Z"
          transform="translate(-9.115 -15.02)"
          fill="#323f4b"
        />
        <path
          id="Path_52"
          data-name="Path 52"
          d="M22.016,18.4a.335.335,0,1,0-.474.474l.976.976a.335.335,0,1,0,.474-.474Z"
          transform="translate(-14.259 -12.168)"
          fill="#323f4b"
        />
        <path
          id="Path_53"
          data-name="Path 53"
          d="M15.678,18.072a.335.335,0,1,0-.474.474l2.346,2.346a.335.335,0,0,0,.474-.474Z"
          transform="translate(-10.045 -11.952)"
          fill="#323f4b"
        />
      </g>
    </svg>
  );
};

export const CateringIconLight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
      width="23"
      height="15.5"
      viewBox="0 0 20.632 14"
    >
      <g id="catering_Icon">
        <path
          id="Path_50"
          data-name="Path 50"
          d="M20.3,10.678H18.941a8.635,8.635,0,0,0-8.29-8.588V.335a.335.335,0,1,0-.67,0V2.089a8.635,8.635,0,0,0-8.29,8.588H.335a.335.335,0,0,0,0,.67H1.69v.023A2.631,2.631,0,0,0,4.318,14H16.313a2.632,2.632,0,0,0,2.628-2.628v-.023H20.3a.335.335,0,0,0,0-.67M10.316,2.751a7.964,7.964,0,0,1,7.955,7.927H2.361a7.964,7.964,0,0,1,7.955-7.927m7.956,8.62a1.961,1.961,0,0,1-1.958,1.958H4.318A1.961,1.961,0,0,1,2.36,11.371v-.023H18.272Z"
          transform="translate(0 0)"
          fill="grey"
        />
        <path
          id="Path_51"
          data-name="Path 51"
          d="M14.28,22.687a.335.335,0,0,0-.474.474l.976.976a.335.335,0,1,0,.474-.474Z"
          transform="translate(-9.115 -15.02)"
          fill="grey"
        />
        <path
          id="Path_52"
          data-name="Path 52"
          d="M22.016,18.4a.335.335,0,1,0-.474.474l.976.976a.335.335,0,1,0,.474-.474Z"
          transform="translate(-14.259 -12.168)"
          fill="grey"
        />
        <path
          id="Path_53"
          data-name="Path 53"
          d="M15.678,18.072a.335.335,0,1,0-.474.474l2.346,2.346a.335.335,0,0,0,.474-.474Z"
          transform="translate(-10.045 -11.952)"
          fill="grey"
        />
      </g>
    </svg>
  );
};
