import * as React from "react";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import { ReactComponent as RadioChecked } from "../../../assets/icons/radio-check.svg";
import { ReactComponent as RadioUnchecked } from "../../../assets/icons/radio-uncheck.svg";

function BpRadio(props) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<RadioChecked />}
      icon={<RadioUnchecked />}
      {...props}
    />
  );
}

export default function CustomizedRadios({ value, setValue }) {
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FormControl>
      <RadioGroup
        defaultValue="store_pickup"
        aria-labelledby="demo-customized-radios"
        name="customized-radios"
        value={value}
        onChange={handleChange}
        className="flex-row"
      >
        <FormControlLabel
          value="store_pickup"
          control={<BpRadio />}
          label="Store Pickup"
          className="m-0"
          sx={{
            "& .MuiTypography-root": {
              font: "normal normal 600 12px/24px Titillium Web",
            },
          }}
        />
        {/* <FormControlLabel
          value="car_pickup"
          control={<BpRadio />}
          label="Car Pickup"
          className="m-0"
          sx={{
            "& .MuiTypography-root": {
              font: "normal normal 600 12px/24px Titillium Web",
            },
          }}
        /> */}
      </RadioGroup>
    </FormControl>
  );
}
