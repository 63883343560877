import React from "react";
import Address from "./address";

const AddressSection = ({ address, i18n, serviceType, branchName }) => {
  return (
    <div className="orderaddress-section">
      <hr />
      <div>
        <Address
          address={address}
          i18n={i18n}
          serviceType={serviceType}
          branchName={branchName}
        />
      </div>
      <hr />
    </div>
  );
};

export default AddressSection;
