export const termsContent = {
  general_term: [
    {
      id: 1,
      heading: "Introduction",
      detail:
        "Welcome to OrderSeazen. These Terms and Conditions govern your use of the OrderSeazen website and its services, including Food Delivery, Food Pickup, Catering Services, and Table Reservation. By accessing or using our services, you agree to be bound by these terms.",
    },
    {
      id: 2,
      heading: "Definitions",
      sub_data: [
        {
          id: 2.1,
          sub_heading: '"OrderSeazen"',
          sub_detail:
            "Reference to OrderSeazen in these terms shall mean the following restaurants: “MELANZANE”, “TRAPANI”, “RECCO”, “MASGOUF”, “OKRA”, “THE GROVE”, “SECCO BY COFFEA” AND “CAFETERIA DAHIYA”.",
        },
        {
          id: 2.2,
          sub_heading: '"Service"',
          sub_detail:
            "The online ordering service of food delivery, food pickup, catering service, or table reservation provided by OrderSeazen.",
        },
        {
          id: 2.3,
          sub_heading: "“Us”, “Our”, “We”",
          sub_detail:
            "Shall mean Seazen Group, OrderSeazen and any affiliate owned directly or indirectly by Seazen Hospitality for Restaurant Management & Catering Company W.LL..",
        },
        {
          id: 2.4,
          sub_heading: '"User", “You”',
          sub_detail:
            "Any individual or entity that accesses or uses the Service.",
        },
        {
          id: 2.5,
          sub_heading: '"Content"',
          sub_detail:
            "All material, including text, graphics, images, and software, available on the Service.",
        },
      ],
    },
    {
      id: 3,
      heading: "Service Description",
      detail:
        "OrderSeazen offers a range of Services, including Food Delivery, Food Pickup, Catering Services, and Table Reservation, available across various brands in the GCC region. Each Service is subject to specific terms outlined herein.",
    },
    {
      id: 4,
      heading: "User Access",
      sub_data: [
        {
          id: 4.1,
          sub_heading: "User Registration",
          sub_detail:
            "Users can register using a mobile phone or email. OTP verification may be required for mobile phone registration.",
        },
        {
          id: 4.2,
          sub_heading: "User Security",
          sub_detail:
            "Users are responsible for safeguarding and maintaining the confidentiality of their access and for all activities that occur under their account.",
        },
        {
          id: 4.3,
          sub_heading: "User Information",
          sub_detail:
            "Users agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.",
        },
        {
          id: 4.4,
          sub_heading: "Use of Website",
          sub_detail:
            "By using Our website, You hereby agree to assume complete responsibility for all your activities, including online ordering. It is further acknowledged that You are entirely responsible for the actions of any individual who accesses our website for online ordering or places an order using an electronic device that remains logged in to, or associated with, your registered account. We do not guarantee that the website will be devoid of errors or that any identified errors will be rectified. Furthermore, We do not warrant that the website will be free of viruses or other detrimental components. By using the website, applications, or engaging in online ordering, you agree to indemnify Us against any claims, judgments, losses, expenses, or liabilities arising from viruses or harm experienced by You due to your utilization of the website.",
        },
      ],
    },
    {
      id: 5,
      heading: "Use of the Service",
      sub_data: [
        {
          id: 5.1,
          sub_heading: "Eligibility",
          sub_detail:
            "Users must be at least 18 years old or have legal parental or guardian consent.",
        },
        {
          id: 5.2,
          sub_heading: "Compliance",
          sub_detail:
            "Users agree to comply with all local laws and regulations regarding online conduct and acceptable content.",
        },
        {
          id: 5.3,
          sub_heading: "Prohibited Conduct",
          sub_detail:
            "Users must not misuse the Service, including attempting unauthorized access, using it for illegal purposes, or infringing on intellectual property rights.",
        },
      ],
    },
    {
      id: 6,
      heading: "Order Placement and Payment",
      sub_data: [
        {
          id: 6.1,
          sub_heading: "Order Placement",
          sub_detail:
            "Orders can be placed through the website for any of the listed services. Each order is subject to acceptance and availability.",
        },
        {
          id: 6.2,
          sub_heading: "Payment Methods",
          sub_detail:
            "Payment methods include credit card and debit cards via online payment gateway. All transactions are secure and encrypted.",
        },
        {
          id: 6.3,
          sub_heading: "Pricing",
          sub_detail:
            "Unless stated otherwise, all prices are inclusive of legally applicable taxes but exclusive of VAT where applicable.",
        },
      ],
    },
    {
      id: 7,
      heading: "Delivery Order",
      sub_data: [
        {
          id: 7.1,
          sub_heading: "Estimated Delivery Time",
          sub_detail:
            "Estimated Delivery Time is only an estimation and may vary based on the production time of menu items and the delivery address selected by the user. We endeavor to deliver your order as promptly as possible but do not guarantee delivery times.",
        },
        {
          id: 7.2,
          sub_heading: "Delivery Areas",
          sub_detail:
            "OrderSeazen provides delivery services to specific areas. Users can check if their location is covered by our delivery service through our website or customer service. Delivery area limitations may apply to certain brands or menu items.",
        },
        {
          id: 7.3,
          sub_heading: "Delivery Minimum Order",
          sub_detail:
            "A minimum order value is required for delivery orders. This value may vary based on the user’s location and the specific brand from which the order is placed. Minimum order requirements will be clearly stated during the order process.",
          last_bold: "(There is no minimum order value for Qatar and Kuwait)",
        },
        {
          id: 7.4,
          sub_heading: "Delivery Charges",
          sub_detail:
            "Delivery charges apply to each order and are based on the delivery location. These charges will be displayed at the time of placing the order. We reserve the right to modify delivery charges without prior notice.",
        },
        {
          id: 7.5,
          sub_heading: "Driver Tip",
          sub_detail:
            "Tipping the driver is a way to show appreciation for the delivery service but is not mandatory. Users can choose to add a tip during the payment process.",
        },
        {
          id: 7.6,
          sub_heading: "Modifying Delivery Order",
          sub_detail:
            "Orders can be modified within approximately Five (5) minutes time frame after placement, subject to the status of the order. Users wishing to make changes should contact customer service immediately. Modifications may be subject to additional charges or may not be possible for orders that are already being processed.",
        },
        {
          id: 7.7,
          sub_heading: "Refund & Cancellation Policy for Delivery Order",
          sub_detail:
            "Users may cancel their delivery order within approximately Five (5) minutes time frame after placing the order. In case of late cancellation, a cancellation fee may apply. Refunds for canceled pickup orders will be processed within up to 14 working days. For any inquiry, our contact details are listed below.",
        },
        {
          id: 7.8,
          sub_heading: "Geolocation Tracking",
          sub_detail:
            "Users hereby consent that We may collect, use, and share precise location data, including the real-time geographic location of the user's computer or mobile device, provided that this is authorized by the User. This location data may be collected and used by Us to display to the User the status, origin and/or destination of an order. In this context, users expressly consent to the sharing of their geolocation data with related parties to successfully fulfill the delivery of the order at any given time.",
        },
      ],
    },
    {
      id: 8,
      heading: "Pickup Order",
      sub_data: [
        {
          id: 8.1,
          sub_heading: "Location for Pickup",
          sub_detail:
            "Pickup orders can be collected from the designated location of the restaurant or brand from which the order is placed. The specific pickup location will be provided in the order confirmation.",
        },
        {
          id: 8.2,
          sub_heading: "Estimated Pickup Time",
          sub_detail:
            "The estimated pickup time will be indicated at the time of order placement. While we strive to ensure that your order is ready for pickup at the specified time, please note that this is an estimate and may be subject to change.",
        },
        {
          id: 8.3,
          sub_heading: "Modifying Pickup Order",
          sub_detail:
            "Users can modify their pickup order within approximately Five (5) minutes time frame after order placement. Any modifications are subject to availability, additional charges, and may require additional waiting time.",
        },
        {
          id: 8.4,
          sub_heading: "Refund & Cancellation Policy for Pickup Order",
          sub_detail:
            "Pickup orders can be canceled within approximately Five (5) minutes time frame after placing the order. In case of late cancellation, a cancellation fee may apply. Refunds for canceled pickup orders will be processed within up to 14 working days. For any inquiry, our contact details are listed below.",
        },
      ],
    },
    {
      id: 9,
      heading: "Catering Services",
      sub_data: [
        {
          id: 9.1,
          sub_heading: "Catering Setup",
          sub_detail:
            "Catering setup will be conducted by our staff according to the agreed-upon requirements. Setup times and requirements must be confirmed in advance.",
        },
        {
          id: 9.2,
          sub_heading: "Catering Service Timeframe",
          sub_detail:
            "Catering services will be provided within the agreed-upon timeframe. Any extensions may be subject to additional charges.",
        },
        {
          id: 9.3,
          sub_heading: "Catering Coverage Areas",
          sub_detail:
            "Catering services are available within specific areas. Coverage areas are subject to change and can be confirmed at the time of booking.",
        },
        {
          id: 9.4,
          sub_heading: "Modifying Catering Order",
          sub_detail:
            "Any changes to the catering order, including menu selections or guest numbers or change date/timing, must be made within at least 24 hours timeframe prior to the event. Late changes may not be accommodated. Any changes may be subject to additional charges.",
        },
        {
          id: 9.5,
          sub_heading: "Refund & Cancellation Policy for Catering Order",
          sub_detail:
            "Cancellations of catering orders must be made within at least 48 hour period before the event date, to be eligible for full refund. Any cancellation prior to 12 hours of the event, a 50% cancellation fee (of the order value) will be charged and any cancellation within less than 12 hours prior to the event, a 100% cancellation fee (of the order value) will be charged. Refunds for canceled catering orders will be processed within up to 14 working days. Refunded amount will be refunded as credit balance which can be redeemed for future orders. For any inquiry, our contact details are listed below.",
        },
        {
          id: 9.6,
          sub_heading: "Liability",
          sub_detail:
            "Our liability for catering services is limited to the provision of food and related services as agreed. We are not liable for indirect damages or losses. We hold no liability for circumstances beyond its control affecting the catering service, which may include, but not limited to; heavy traffic, road blocks, motor accidents, power shutdown, or natural disasters.",
        },
      ],
    },
    {
      id: 10,
      heading: "Table Reservation",
      sub_data: [
        {
          id: 10.1,
          sub_heading: "Queue request form",
          sub_detail:
            "Users can request a table through a request form available on our website. They can their booking at the same time reservation. Queue using for waiting list, this is a request to join the waiting list for a table and does not guarantee immediate availability. Users will be notified about the estimated wait time and table availability.",
        },
        {
          id: 10.2,
          sub_heading: "Special Requests",
          sub_detail:
            "Special requests (e.g., dietary needs, seating preferences) can be made at the time of reservation but are not guaranteed and are subject to the restaurant's discretion and capacity.",
        },
        {
          id: 10.3,
          sub_heading: "Compliance with Restaurant Policies",
          sub_detail:
            "Users are expected to comply with the restaurant's policies, including dress codes, conduct guidelines, and any other rules set by the establishment.",
        },
      ],
    },
    {
      id: 11,
      heading: "User’s obligations and responsibilities",
      sub_data: [
        {
          id: 11.1,
          sub_detail:
            "Users are fully responsible for the proper use of, and access to, their profile and other contents in accordance with the current legislation, be it national of the country from which they are using the Service, as well as with the principles of good faith, morals, generally accepted customs and public order. Specifically, they undertake to diligently comply with these Terms & Conditions.",
        },
        {
          id: 11.2,
          sub_detail:
            "Users shall refrain from using their profile for illegal purposes or with illegal results that harm third-party rights and interests or that may in any way damage, disable, affect or impair the Services. In addition, they are prohibited from hindering other Users’ normal use or enjoyment of the Services/Website.",
        },
        {
          id: 11.3,
          sub_detail:
            "Any person who breaches the above obligations shall be liable for any loss or damage caused by them. We will accept no liability for any consequences, loss or damage that may arise from such illegal use or access by third parties.",
        },
        {
          id: 11.4,
          sub_detail:
            "In general, Users undertake, by way of example without limitation:",
          child_sub_data: [
            {
              id: 1,
              child_sub_detail:
                "To refrain from altering or modifying the Website, in full or in part, by bypassing, disabling or in any other way tampering with, its functions or services;",
            },
            {
              id: 2,
              child_sub_detail:
                "To refrain from infringing industrial and intellectual property rights or the personal data protection legislation of our businesses and affiliates;",
            },
            {
              id: 3,
              child_sub_detail:
                "To refrain from accessing other Users’ email accounts;",
            },
            {
              id: 4,
              child_sub_detail:
                "To refrain from introducing computer viruses, corrupted files or any other software that may cause damage or alterations to us or third parties’ contents or systems;",
            },
          ],
        },
      ],
    },
    {
      id: 12,
      heading: "Termination",
      detail:
        "We reserve the right to terminate suspend or delete a User’s Account and their access or use of the Services for any reason for convenience and in the event of any breach by the User’s of the obligations and responsibilities set out in these Terms.",
    },
    {
      id: 13,
      heading: "Intellectual Property Rights",
      sub_data: [
        {
          id: 13.1,
          sub_heading: "Ownership",
          sub_detail:
            "All content on OrderSeazen, including trademarks, logos, and service marks, is the property of us or our licensors and is protected by intellectual property laws.",
        },
        {
          id: 13.2,
          sub_heading: "Use of Content",
          sub_detail:
            "Users may not reproduce, distribute, or otherwise use any Content without express written permission by us.",
        },
      ],
    },
    {
      id: 14,
      heading: "Liability and Disclaimer",
      sub_data: [
        {
          id: 14.1,
          sub_heading: "Limitation of Liability",
          sub_detail:
            "We will not be liable for any indirect, incidental, special, consequential, or punitive damages arising from the use of the Service.",
        },
        {
          id: 14.2,
          sub_heading: "No Warranties",
          sub_detail:
            'The Service is provided "as is" without any warranties, express or implied.',
        },
      ],
    },
    {
      id: 15,
      heading: "Indemnification",
      detail:
        "Users agree to indemnify and hold harmless us and our affiliates, officers, agents, employees, and partners from any claim or demand made by any third party due to or arising out of the User's use of the Service.",
    },
    {
      id: 16,
      heading: "Changes to Terms",
      detail:
        "We reserves the right to modify these Terms and Conditions at any time. Continued use of the Service following such changes will indicate your acknowledgment and agreement to be bound by the revised terms.",
    },
    {
      id: 17,
      heading: "Governing Law and Jurisdiction",
      detail:
        "These Terms and Conditions are governed by the laws of the country from which the User is using the Services, without regard to its conflict of law principles. Any disputes arising from these terms will be subject to the exclusive jurisdiction of the courts of the country from which the User is using the Services.",
    },
    {
      id: 18,
      heading: "Data Protection and Privacy",
      detail:
        "Refer to our Privacy Policy for detailed information on data collection, usage, and protection.",
    },
  ],
};

export const tableData = [
  {
    id: 1,
    service: "Customer Service Hotline - Kuwait & International",
    number: "+9651818555",
  },
  {
    id: 1,
    service: "Customer Service WhatsApp - Kuwait & International",
    number: "+9651818555",
  },
  {
    id: 1,
    service: "Customer Service - KSA",
    number: "+966 115201197",
  },
  {
    id: 1,
    service: "Customer Service - Bahrain",
    number: "+973 16163526",
  },
  {
    id: 1,
    service: "Customer Service - Qatar",
    number: "+974 4419 6605",
  },
  {
    id: 1,
    service: "Customer Service - UAE",
    number: "+971 4215 6882",
  },
  {
    id: 1,
    service: "Customer Service - Oman",
    number: "+968 24442610",
  },
];
