import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import Navbar from "../../components/header";
import PickupBranchesDetails from "../../components/restaurant/pickup/pickupBranchesDetails";
import { Footer } from "../../components/footer/footerContent";

import { retrunLanguage } from "../../utils/languageSelect";

import { paths } from "../../routes/paths";

import "./style.css";
import { filterPickupRestaurants } from "../../utils/filterPickupRestaurant";

export const NearMe = () => {
  const { i18n, t } = useTranslation("pickup");

  const token = localStorage.getItem("token");

  const branchHours = useSelector((state) => state.resturants.branchHours);
  const restaurantsData = useSelector(
    (state) => state.resturants.resturantsData
  );

  const [pickupRestaurantBranches, setPickupRestaurantBranches] =
    useState(null);

  const convertToAmPm = (timeStr) => {
    let [hours, minutes] = timeStr.split(":");

    hours = parseInt(hours);

    let period = hours >= 12 ? t("pm") : t("am");

    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }

    return hours + ":" + minutes + " " + period;
  };

  useEffect(() => {
    let branchesAndHours = [];
    if (branchHours && branchHours.length > 0) {
      branchHours.forEach((branch) => {
        if (branch.branch_hours.length > 0) {
          branchesAndHours.push({
            ...branch,
            weekdayHours:
              convertToAmPm(branch.branch_hours[0].from_time) +
              " - " +
              convertToAmPm(branch.branch_hours[0].to_time),
            weekendHours:
              convertToAmPm(
                branch.branch_hours[branch.branch_hours.length - 2].from_time
              ) +
              " - " +
              convertToAmPm(
                branch.branch_hours[branch.branch_hours.length - 2].to_time
              ),
          });
        } else {
          branchesAndHours.push({
            name: branch?.name_en,
            weekdayHours: "N/A",
            weekendHours: "N/A",
          });
        }
      });

      const pickupRestaurants = filterPickupRestaurants(
        branchesAndHours,
        restaurantsData
      );
      setPickupRestaurantBranches(pickupRestaurants);
    }
  }, [branchHours]);

  return (
    <div className="nearme-screen-container">
      <div className="pickup-section">
        <div style={{ padding: "8px" }}>
          <Navbar
            profile
            logo
            languageIcon
            profileIconUrl={token ? paths.profile : paths.guest_profile}
          />
        </div>

        <p className="nearme-text">{t("pickupFrom")}</p>

        {pickupRestaurantBranches && (
          <PickupBranchesDetails branchesData={pickupRestaurantBranches} />
        )}
      </div>

      <div
        className={`${
          retrunLanguage(i18n) ? "branch-footer-ar" : "branch-footer-en"
        }`}
      >
        <Footer showFooterSection />
      </div>
    </div>
  );
};
