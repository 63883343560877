import React from "react";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";

import Card from "../../components/adressList";

import { retrunLanguage } from "../../utils/languageSelect";

const SelectLocation = (props) => {
  const { t, i18n } = useTranslation();

  const { openAddressPopup, handleClose, chooseAddress, navigateUser } = props;

  const addressData = useSelector((state) => state.address.addressData);

  return (
    <Dialog
      open={openAddressPopup}
      onClose={() => handleClose()}
      sx={{
        margin: "0",
        textAlign: "center",
      }}
      PaperProps={{
        sx: {
          overflow: "visible",
          margin: "0",
          height: "310px",
          bottom: "0",
          position: "fixed",
          width: "375px",
          left: retrunLanguage(i18n) ? "unset" : "0",
          right: retrunLanguage(i18n) ? "0" : "unset",
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",

          "@media (max-width: 500px)": {
            width: "100%",
          },
        },
      }}
    >
      <DialogTitle className="dialog-title">
        {t("chooseYourLocation")}
      </DialogTitle>
      <DialogContent
        sx={{
          fontFamily: "Titillium Web, sans-serif",
          fontWeight: 600,
          scrollbarWidth: "thin",
        }}
      >
        {addressData.map((data) => (
          <div key={data.id} className="parent-div">
            <Card
              iconUrl={
                data.address_type === "house"
                  ? "/assets/img/salmiya home.svg"
                  : data.address_type === "appartment"
                  ? "/assets/img/salmiya appart.svg"
                  : "/assets/img/salmiya office.svg"
              }
              data={data}
              home={true}
              chooseAddress={() => chooseAddress(data)}
            />
          </div>
        ))}
      </DialogContent>
      <div className="button-div">
        <Button className="location-button" onClick={navigateUser}>
          {addressData?.length === 0
            ? t("addNewAddress")
            : t("chooseNewLocation")}
        </Button>
      </div>
    </Dialog>
  );
};

export default SelectLocation;
