import axiosInstance from "../axiosInstance";
import API_ENDPOINTS from "../endpoints";

import { handleApiError } from "../utils/errorHandling";

const getAllRestaurantsBranches = async () => {
  try {
    // Make the GET request to fetch all restaurants banner.
    const { data } = await axiosInstance.get(
      API_ENDPOINTS.HOME.GET_ALL_RESTAURANT_BRANCHES + "?language=en"
    );
    return data;
  } catch (error) {
    // Use the handleApiError function for error handling
    handleApiError(error);
  }
};

export default getAllRestaurantsBranches;
