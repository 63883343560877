export const viewItemListScript = (itemList, restaurantName, typeStatus) => {
  const dataLayer = window.dataLayer || [];
  // Clear the previous ecommerce object.
  dataLayer.push({ ecommerce: null });
  // Map the items in itemList to the format expected by the dataLayer push.
  const items = itemList?.map((item, index) => ({
    item_name: item.name_en, // Assuming name_en is the English name
    item_id: item.id, // Unique ID as string
    price: parseFloat(item.price.replace("KWD ", "")), // Convert price to float
    item_category: item.category_name_en, // Placeholder, update as needed
    item_brand: restaurantName, // Placeholder, update as needed
    index: index, // 1-based index
    quantity: 1, // Default quantity
  }));

  // Push the new event to the dataLayer.
  dataLayer.push({
    event: "view_item_list",
    service: typeStatus,
    ecommerce: {
      items: items,
    },
  });
};

export const viewItemScript = (itemList, restaurantName, typeStatus) => {
  const dataLayer = window.dataLayer || [];

  dataLayer.push({ ecommerce: null });

  const items = itemList?.map((item, index) => ({
    item_name: item.name_en,
    item_id: item.id,
    item_category: item.category_name_en,
    item_brand: restaurantName,
    index: index,
    quantity: 1,
  }));

  dataLayer.push({
    event: "view_item",
    service: typeStatus,
    ecommerce: {
      items: items,
    },
  });
};

export const addToCartScript = (itemList, typeStatus, quantity, restaurantName) => {
  const dataLayer = window.dataLayer || [];

  dataLayer.push({ ecommerce: null });

  const items = itemList?.map((item, index) => ({
    item_name: item.name_en,
    item_id: item.id,
    item_category: item.category_name_en,
    index: index,
    quantity: quantity || 1,
    item_brand: restaurantName
  }));

  dataLayer.push({
    event: "add_to_cart",
    service: typeStatus,
    ecommerce: {
      items: items,
    },
  });
};

export const viewCartScript = (itemList, restaurantName, typeStatus) => {
  const dataLayer = window.dataLayer || [];

  dataLayer.push({ ecommerce: null });

  const items = itemList?.map((item, index) => ({
    item_name: item.itemName,
    item_id: item.id,
    price: parseFloat(item.price.replace("KWD ", "")),
    item_category: item.category_name_en,
    item_brand: restaurantName,
    index: index,
    quantity: item.quantity || 1,
  }));

  dataLayer.push({
    event: "view_cart",
    service: typeStatus,
    ecommerce: {
      items: items,
    },
  });
};

export const beginCheckoutScript = (
  itemList,
  typeStatus,
  totalOrderValue,
  restaurantName
) => {
  const dataLayer = window.dataLayer || [];
  dataLayer.push({ ecommerce: null });

  const items = itemList?.map((item, index) => ({
    item_name: item.itemName,
    item_brand: restaurantName,
    item_id: item.id,
    price: parseFloat(item.price.replace("KWD ", "")),
    item_category: item.category_name_en,
    index: index,
    quantity: item.quantity || 1,
  }));

  dataLayer.push({
    event: "begin_checkout",
    service: typeStatus,
    customerTotalOrderValue: totalOrderValue, //total of all checkout item price
    coupon: "SUMMER_FUN",
    ecommerce: {
      items: items,
    },
  });
};

export const purchaseScript = (
  itemList,
  restaurantName,
  typeStatus,
  transactionId,
  totalValue
) => {
  // Ensure dataLayer is an array
  const dataLayer = window.dataLayer || (window.dataLayer = []);

  // Clear previous ecommerce data
  dataLayer.push({ ecommerce: null });

  // Parse items
  const items =
    itemList?.map((item) => ({
      item_name: item?.menu_item?.name_en || "",
      item_id: item?.menu_item?.menu_id || "",
      price: item?.unit_price || 0,
      item_category: item?.category_name_en || "",
      item_category2: typeStatus || "",
      item_brand: restaurantName || "",
      quantity: item?.quantity || 1,
    })) || [];

  // Create the purchase event data
  const purchaseEventData = {
    event: "purchase",
    service: typeStatus || "",
    ecommerce: {
      transaction_id: transactionId || "",
      value: totalValue || 0, // Ensure totalValue is a number
      tax: "0",
      shipping: "0",
      currency: "KWD",
      items: items,
    },
  };
  // Push purchase event to dataLayer
  dataLayer.push(purchaseEventData);
};

export const heroSelectScript = (categoryType, itemName) => {
  const dataLayer = window.dataLayer || [];
  dataLayer.push({
    event: "hero_select",
    hero_category: categoryType, //this should be replaced with an actual
    hero_item: itemName || "", // (Text on button click)
  });
};

export const selectServiceScript = (service, sub_service) => {
  const dataLayer = window.dataLayer || [];
  dataLayer.push({
    event: "select_service",
    service: service, //this should be replaced with an actual
    sub_service: sub_service, //this should be replaced with an actual
  });
};
export const selectServiceAvailable = (service, sub_service,restaurantName) => {
  const dataLayer = window.dataLayer || [];
  dataLayer.push({
    event: "select_available",
    service: service, //this should be replaced with an actual
    sub_service: sub_service, //this should be replaced with an actual
    brand_name: restaurantName
  });
};
export const orderCompletedScript = () => {
  const dataLayer = window.dataLayer || [];
  dataLayer.push({
    event: "orderCompleted",
  });
};
