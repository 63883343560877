import { Typography, styled, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { retrunLanguage } from "../../../utils/languageSelect";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import "./style.css";
import ScheduleDialog from "../../shared/updatedSchedulePicker";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useLocation } from "react-router-dom";

const ScheduleOrder = ({
  orderType,
  branchHours,
  setRestaurantOpen,
  orderTime,
  selected,
  openDatePicker,
  setOpenDatePicker,
  navigateTo,
}) => {
  const { t, i18n } = useTranslation("translation");
  const location = useLocation();

  const [dropDown, setDropdown] = useState(false);
  const [openTimePickerPopup, setOpenTimePickerPopup] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [previousDate, setPreviousDate] = useState(null);
  const [valueForDate, setValueForDate] = useState(null);

  const isPreviousDateDisabled = (date) => {
    const currentDate = dayjs(new Date());

    return date < currentDate;
  };

  const pickDate = localStorage.getItem("pickDate");
  const isAsap = localStorage.getItem("isAsap");
  const pickTime = localStorage.getItem("pickTime");
  const branchId = localStorage.getItem("branchId");

  const StyledOuterContainerIcon = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.themeColor.main, // Apply the background color
    // Add any other styles you need for the outer container here
  }));

  const asapHandler = () => {
    if (branchHours?.length !== 0) {
      const filterBranch = branchHours?.find(
        (branch) => branch.id === +branchId
      );

      const date = new Date();
      const options = { weekday: "long" };
      const dayName = date.toLocaleDateString("en-US", options);

      const statusObject = filterBranch?.branch_hours?.find(
        (branch) => branch.week_day === dayName
      );

      if (statusObject?.openingStatus === "Open") {
        asapCall();
      } else if (statusObject?.openingStatus === "Close") {
        setRestaurantOpen(true);
      }
    } else {
      asapCall();
    }
  };

  const asapCall = () => {
    setDropdown(false);
    let currentTime = new Date();

    currentTime.setMinutes(currentTime.getMinutes() + orderTime());

    localStorage.setItem("pickTime", dayjs(currentTime).format("hh:mm a"));
    localStorage.setItem(
      "pickDate",
      dayjs(currentTime).format("MMM DD, YYYY-dddd")
    );
    localStorage.setItem("isAsap", true);
  };

  useEffect(() => {
    if (location?.state?.orderTime === "scheduled") {
      setOpenDatePicker(true);
    }
  }, []);

  return (
    <>
      <div
        className={`right_dropdown_container ${
          retrunLanguage(i18n) && "dropdown-right-ar"
        }`}
      >
        <div className="right_dropdown_container_heading_container">
          {t(`menu.${orderType}Time`)}
        </div>

        <Dropdown
          onToggle={() => setDropdown(!dropDown)}
          show={dropDown}
          style={{ width: "100%", scrollbarWidth: "none" }}
        >
          <Dropdown.Toggle
            variant="success"
            id="dropdown-basic"
            style={{
              backgroundColor: "transparent",
              color: "#323F4B",
              fontFamily: "Titillium Web",
              fontSize: "10px",
              fontWeight: "700",
              border: "none",
              padding: "0",
              display: isAsap === "false" && "flex",
              justifyContent: isAsap === "false" && "center",
              alignItems: isAsap === "false" && "center",
              // marginLeft: isAsap === "true" ? "0px" : "18px",
              width: "100%",
            }}
          >
            {isAsap === "true" ? (
              <>
                {pickDate?.split("-")[0]} {t("asap", { ns: "dialog" })}
              </>
            ) : (
              <div className="schedule-detail">
                <span>
                  {pickDate?.split("-")[0]} {pickTime}
                </span>
              </div>
            )}
            <StyledOuterContainerIcon
              className="dropdown_icon_container"
              style={{
                marginRight: retrunLanguage(i18n) ? "2px" : "0px",
                top: isAsap === "false" ? "0px" : "5px",
              }}
            >
              <img src="/assets/img/dropdown.svg" alt="chevron_down_icon" />
            </StyledOuterContainerIcon>
          </Dropdown.Toggle>

          <Dropdown.Menu
            style={{
              backgroundColor: "#F0F6F5",
              color: "#323F4B",
              fontFamily: "Titillium Web",
              fontSize: "11pt",
              fontWeight: "bold",
              border: "none",
              transform: "translate(0px, -22px)",
              // top: "12px",
            }}
          >
            <Dropdown.Item
              defaultChecked
              onClick={asapHandler}
              className={retrunLanguage(i18n) && "name-ar"}
            >
              {t("asap", { ns: "dialog" })}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                // setOpenSchedulePopup(true);
                setOpenDatePicker(true);
                document.body.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
              }}
              className={retrunLanguage(i18n) && "name-ar"}
            >
              {t("scheduleLater", { ns: "dialog" })}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      {isAsap === "true" &&
        ((selected !== null && selected?.delivery_time) ||
          (orderType === "pickup" && orderTime())) && (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{
              bgcolor: (theme) => theme.palette.themeColor.main,
              color: "common.white",
              p: 1,
              borderRadius: "8px",
              position: "absolute",
              width: "33px",
              height: "32px",
              // left: "-7px",
              top: "14px",
              right: isAsap === "true" ? "12px" : "10px",
            }}
            spacing={0}
          >
            <Typography
              variant="h4"
              fontWeight={600}
              sx={{
                lineHeight: 1,
                fontSize: "19px",
              }}
            >
              {orderTime()}
            </Typography>

            <Typography
              variant="caption"
              fontWeight={300}
              sx={{
                lineHeight: 1,
                fontSize: "6px",
              }}
            >
              {t("menu.minute")}
            </Typography>
          </Stack>
        )}

      <ScheduleDialog
        openTimePickerPopup={openTimePickerPopup}
        setOpenTimePickerPopup={setOpenTimePickerPopup}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        setPreviousDate={setPreviousDate}
        previousDate={previousDate}
        branchId={branchId}
        navigateTo={navigateTo}
      />
      {/*need to make a single component LocalizationProvider & MobileDatePicker*/}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileDatePicker
          sx={{
            display: "none",
          }}
          open={openDatePicker}
          disablePast
          onChange={(date) => {
            const selectedTime = dayjs(date);
            const beforeDate = selectedTime.subtract(1, "day").format("dddd");

            if (isPreviousDateDisabled(date)) {
              setPreviousDate(null);
            } else {
              setPreviousDate(beforeDate);
            }

            setSelectedDate(date);
            setValueForDate(date);
          }}
          value={valueForDate}
          onAccept={(date) => {
            const selectedTime = dayjs(date);
            const beforeDate = selectedTime.subtract(1, "day").format("dddd");

            if (isPreviousDateDisabled(date)) {
              setPreviousDate(null);
            } else {
              setPreviousDate(beforeDate);
            }

            setSelectedDate(date);
            setValueForDate(date);

            setOpenTimePickerPopup(true);
            setOpenDatePicker(false);
          }}
          onClose={() => {
            setOpenDatePicker(false);

            setTimeout(() => {
              setValueForDate(null);
            }, 100);
          }}
        />
      </LocalizationProvider>
    </>
  );
};

export default ScheduleOrder;
