import axiosInstance from "../axiosInstance";
import API_ENDPOINTS from "../endpoints";

import { handleApiError } from "../utils/errorHandling";

const supportDeliveryByArea = async (area_name, service_type, token) => {
  try {
    let type = "";

    if (service_type === "pickup") {
      type = "pick up";
    } else {
      type = service_type;
    }

    const body = {
      area_name,
      service_type: type,
    };

    // Make the POST request for support-delivery-by-area
    const { data } = await axiosInstance.post(
      API_ENDPOINTS.HOME.SUPPORT_DELIVERY_BY_AREA,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    // Use the handleApiError function for error handling
    handleApiError(error);
  }
};

export default supportDeliveryByArea;
