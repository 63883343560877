import React, { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import getAllRestaurantsBanner from "../../../api/home/getAllRestaurantsBanner";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import { getRestaurantsBanner } from "../../../store/reducers/resturantsSlice";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Skeleton } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { retrunLanguage } from "../../../utils/languageSelect";
import { heroSelectScript } from "../../../utils/conversionScripts";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";

const HomeBannerSlider = () => {
  const { i18n } = useTranslation();
  const restaurantBanner = useSelector(
    (state) => state.resturants.resturantsBanner
  );

  const ranOnceRef = useRef(false);
  const dispatch = useDispatch();
  const videoRef = useRef(null);
  const sliderRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);
  const [sliderPaused, setSliderPaused] = useState(false);

  const settings = {
    dots: true,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    autoplay: !sliderPaused,
    fade: true,
    beforeChange: (current, next) => {
      if (restaurantBanner[next]?.banner_type === "video") {
        setSliderPaused(true);
      } else {
        setSliderPaused(false);
      }
    },
    afterChange: (current) => {
      if (restaurantBanner[current]?.banner_type === "video") {
        videoRef.current?.play();
      }
    },
  };

  const handleBannerClick = (redirectUrl) => {
    window.open(redirectUrl, "_blank");
  };

  useEffect(() => {
    if (!ranOnceRef.current) {
      getAllRestaurantsBanner(3)
        .then((res) => {
          dispatch(getRestaurantsBanner(res.data));
        })
        .catch((err) => {
          console.log(err);
        });
      ranOnceRef.current = true;
    }
  }, [dispatch]);

  useEffect(() => {
    if (restaurantBanner[0]?.banner_type === "video") {
      setSliderPaused(true);
      videoRef.current?.play();
    }
  }, [restaurantBanner]);

  const handleVideoEnd = () => {
    setSliderPaused(false);
    sliderRef.current?.slickNext(); // Move to the next slide
    setTimeout(() => {
      // Ensure autoplay is activated after moving to the next slide
      sliderRef.current?.slickPlay();
    }, 100); //
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  return (
    <div className="slick-slider-wrapper">
      <Slider
        {...settings}
        ref={sliderRef}
        className={retrunLanguage(i18n) ? "float-left" : ""}
      >
        {restaurantBanner.map((slide, index) => (
          <div className="home-banner-images" key={index}>
            {slide.banner_type === "video" ? (
              <div className="video-container">
                <video
                  ref={videoRef}
                  src={slide.video_url}
                  muted={isMuted}
                  playsInline // Prevent fullscreen on mobile
                  onEnded={handleVideoEnd}
                  onClick={() => handleBannerClick(slide.video_redirect_url)}
                  controls={false}
                  autoPlay
                  loop={false}
                  className="banner-video"
                />
                <button className="mute-button" onClick={toggleMute}>
                  {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
                </button>
              </div>
            ) : (
              <LazyLoadImage
                onClick={() => {
                  heroSelectScript("Banner", slide.name_en);
                  handleBannerClick(slide.redirect_url_en);
                }}
                src={retrunLanguage(i18n) ? slide.banner_ar : slide.banner_en}
                alt="restaurant banner"
                className="banner-image"
                placeholder={
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={"100%"}
                  />
                }
              />
            )}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default HomeBannerSlider;
