import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import { paths } from "../../routes/paths";

import { CustomCarousel } from "./customCarousel";

import "./style.css";
import { useTranslation } from "react-i18next";
import { heroSelectScript } from "../../utils/conversionScripts";

export default function DraggableSlider(props) {
  const {
    selectedLocation,
    setOpenAddressPopup,
    setFromPopular,
    setPopularId,
    setBranchId,
    setRestaurantId,
    setDistanceIsFar,
    setRestaurantOpen,
    apiCall,
    apiQuery,
    title,
  } = props;

  // const country = useSelector((state) => state.auth.country);

  const {
    data: popularDishesResponse,
    isLoading,
    error,
    isError,
    refetch,
  } = useQuery({
    queryKey: [apiQuery],
    queryFn: () => apiCall(3),
    onSuccess: (data) => {
      console.log("data", data);
    },
  });

  // useEffect(() => {
  //   refetch();
  // }, [country]);

  const { data: popularDishes } = popularDishesResponse || {};

  return (
    <div className="App">
      <div className="slider-tile">
        {popularDishes?.length > 0 && <h3>{title}</h3>}
      </div>
      <CustomCarousel imageInfo={popularDishes}>
        {popularDishes?.map((info, index) => {
          return (
            <Box
              id={info.id}
              key={index}
              index={index}
              imageUrl={info.item_image}
              caption={info?.name_en}
              captionImage={info.logo}
              additionalText={info.currency_code}
              inlineText={info.price.toFixed(3)}
              isFirstCard={index === 0}
              setOpenAddressPopup={setOpenAddressPopup}
              selectedLocation={selectedLocation}
              setFromPopular={setFromPopular}
              setPopularId={setPopularId}
              setBranchId={setBranchId}
              restaurant_id={info.restaurant_id}
              setRestaurantId={setRestaurantId}
              setDistanceIsFar={setDistanceIsFar}
              setRestaurantOpen={setRestaurantOpen}
              branchId={info.branch_id}
              title={title}
            />
          );
        })}
      </CustomCarousel>
    </div>
  );
}

function Box({
  id,
  index,
  imageUrl,
  caption,
  captionImage,
  additionalText,
  inlineText,
  isFirstCard,
  setOpenAddressPopup,
  selectedLocation,
  setFromPopular,
  setPopularId,
  setBranchId,
  restaurant_id,
  setRestaurantId,
  setDistanceIsFar,
  branchId,
  setRestaurantOpen,
  title,
}) {
  const captionLines = caption.split("\n");
  const captionImageClass = isFirstCard
    ? "first-card-caption-image"
    : index === 2
    ? "third-card-caption-image"
    : "";
  const additionalTextClass = isFirstCard ? "first-card-additional-text" : "";
  const navigate = useNavigate();

  const resturantsData = useSelector(
    (state) => state.resturants.resturantsData
  );
  const { i18n } = useTranslation();

  return (
    <div
      className="box"
      onClick={() => {
        localStorage.setItem("restaurantIdForRedirect", restaurant_id);
        localStorage.setItem("restaurantID", restaurant_id);
        localStorage.setItem("menuDetailId", id);
        localStorage.setItem("branchId", branchId);

        const restName = resturantsData?.find(
          (res) => res.id === restaurant_id
        );
        const itenName = `${restName?.name_en} ${caption}`;

        heroSelectScript(title, itenName);

        localStorage.setItem("restaurantName", restName?.name_en);

        setPopularId(id);
        setBranchId(branchId);
        setRestaurantId(restaurant_id);
        navigate(paths.details + "/" + id, {
          state: {
            id: id,
            loc: "popular",
            restaurant_id: restaurant_id,
            branch_id: branchId,
          },
        });
      }}
    >
      <img className="box-image" src={imageUrl} alt="Image" />
      <div className={`box-caption ${i18n ? "box-caption-ar" : ""}`}>
        <div>
          <img
            src={captionImage}
            alt="Caption Image"
            className={`caption-image ${captionImageClass}`}
          />
        </div>
        <div className="box-text">
          {captionLines.map((line, index) => (
            <React.Fragment key={index}>
              {line}
              {index !== captionLines.length - 1 && <br />}
            </React.Fragment>
          ))}
        </div>
        <div className="price-wrapper">
          <div className="text-style">
            <p className={additionalTextClass}>{additionalText}</p>
          </div>
          <div className="inline-text">{inlineText}</div>
        </div>
      </div>
    </div>
  );
}
