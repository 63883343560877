import React, { useState } from "react";
import { useSelector } from "react-redux";

import DeliveryDetails from "..";
import ErrorInformation from "../../../../errorInformation";
import MenuCardListContainer from "../../../menu/menu_card_list_container";
import MenuItems from "../../../menu/menu_items";

const DeliveryConditionalComponent = ({
  locationDeliver,
  setLocationDeliver,
  deliveryAvailable,
  setDeliveryAvailable,
  resturantId,
  errorMessage,
  restaurantBranchHours,
  restaurantDetail,
  setOpenModal,
  setSelectedItemToDeliver,
  scheduleForLater,
  fromDetail,
  setFromDetail,
  setNotDeliver,
  setRestaurantOpen,
  onDelivery,
  setOrderAmFromDelivery,
  branchIdState,
}) => {
  const categoryList = useSelector(
    (state) => state?.resturants?.listItemByCategory
  );
  const categories = useSelector((state) => state?.resturants?.listCategory);
  const categoryLoading = useSelector(
    (state) => state?.resturants?.categoryLoading
  );
  const [addressSelected, setAddressSelected] = useState();
  const [selectAddressNotDeliver, setSelectAddressNotDeliver] = useState(false);

  return (
    <>
      <DeliveryDetails
        setLocationDeliver={setLocationDeliver}
        restaurantBranchHours={restaurantBranchHours}
        scheduleForLater={scheduleForLater}
        fromDetail={fromDetail}
        setFromDetail={setFromDetail}
        setSelectAddressNotDeliver={setSelectAddressNotDeliver}
        selectAddressNotDeliver={selectAddressNotDeliver}
        onDelivery={onDelivery}
        setOrderAmFromDelivery={setOrderAmFromDelivery}
        addressSelected={addressSelected}
      />
      {locationDeliver && <ErrorInformation errorMessage={errorMessage} />}
      <MenuItems
        itemDetail={categoryList}
        itemCategoryList={categories}
      />
      <MenuCardListContainer
        itemDetail={categoryList}
        itemCategoryList={categories}
        locationDeliver={locationDeliver}
        resturantId={resturantId}
        restaurantBranchHours={restaurantBranchHours}
        restaurantDetail={restaurantDetail}
        setOpenModal={setOpenModal}
        setSelectedItemToDeliver={setSelectedItemToDeliver}
        ItemsCategoryLoading={categoryLoading}
        branchId={branchIdState}
        typeStatus={"delivery"}
        setAddressSelected={setAddressSelected}
        setSelectAddressNotDeliver={setSelectAddressNotDeliver}
        // setNotDeliver={setNotDeliver}
        // notDeliver={notDeliver}
      />
    </>
  );
};

export default DeliveryConditionalComponent;
