import React from "react";

import { useTranslation } from "react-i18next";

import "../../screens/order/style.css";

const UpdateSection = ({ arrivalDate, arrivalTime }) => {
  const { t } = useTranslation("order");

  return (
    <div className="update-sec">
      <p id="text-for-arrival">{t("estimated")}</p>
      <p className="ordercurrent-time">
        {arrivalDate} {arrivalTime}
      </p>
      {/* <CurrentTime/> */}
    </div>
  );
};

export default UpdateSection;
