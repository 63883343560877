import axiosInstance from "../axiosInstance";
import API_ENDPOINTS from "../endpoints";
import { handleApiError } from "../utils/errorHandling";

export const GET_ITEM_CATEGORY_LIST = "get_item_category_list_query_key";

const getItemCategoryList = async (restaurantId) => {
  try {
    // Make the GET request to fetch item categories for a restaurant.
    const { data } = await axiosInstance.get(
      API_ENDPOINTS.RESTAURANT.GET_RESTAURANT_DETAIL +
        `${restaurantId}` +
        `/branch/${localStorage.getItem("branchId")}/categories?language=en`
    );
    const sortByDisplayOrder = (data) =>
      data.sort((a, b) => a.display_order - b.display_order );

    // Create a new variable to store the sorted data
    const sortedData = sortByDisplayOrder(data.data);
    const sortedResponse = { ...data, data: sortedData };

    return sortedResponse;
  } catch (error) {
    // Use the handleApiError function for error handling
    handleApiError(error);
  }
};

export default getItemCategoryList;
