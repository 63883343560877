import React from "react";

import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";

import { retrunLanguage } from "../../utils/languageSelect";

const RemoveFromCart = (props) => {
  const { t, i18n } = useTranslation("common");

  const {
    clearCartModal,
    setClearCartModal,
    textToShow,
    clearFunction,
    itemsAfterRemove,
    removingItem,
    setDisableMenu,
  } = props;

  return (
    <Dialog
      open={clearCartModal}
      onClose={() => {
        setClearCartModal(false);
        setDisableMenu && setDisableMenu(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="not-delivered"
      sx={{
        overflow: "hidden",
        "& .MuiDialog-paper": {
          width: "327px",
          textAlign: "center",
          overflow: "visible",
          margin: "27px",
          scrollbarWidth: "none",
          right: retrunLanguage(i18n) ? "-5px" : "unset",

          "@media (max-width: 500px)": {
            width: "375px",
            margin: "30px",
          },

          "@media (max-width: 400px)": {
            width: "90%",
            margin: "25px",
          },
        },
      }}
    >
      <DialogTitle id="alert-dialog-title" className="dialog-title">
        <img src="/assets/img/exclaim.svg"></img>
      </DialogTitle>
      <DialogContent
        className="content"
        sx={{
          scrollbarWidth: "none",
        }}
      >
        <DialogContentText id="alert-dialog-description">
          <div className="text-detail">{textToShow}</div>

          <ListItemsNotDelivered
            handleClose={() => {
              setClearCartModal(false);
              setDisableMenu && setDisableMenu(false);
            }}
            cartItems={itemsAfterRemove}
            clearFunction={clearFunction}
            removingItem={removingItem}
            t={t}
          />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

const ListItemsNotDelivered = (props) => {
  const { cartItems, handleClose, clearFunction, removingItem, t } = props;

  return (
    <Box>
      <Box className="to-scroll">
        {cartItems?.map((item) => {
          const checkIfImageExists = () => {
            const img = new Image();
            img.src = item?.itemImage;

            if (img.complete) {
              return true;
            } else {
              img.onload = () => {
                return true;
              };

              img.onerror = () => {
                return false;
              };
            }
          };

          return (
            <Box key={item.id} className="items-not-delvered">
              {checkIfImageExists() === true ? (
                <div className="items-image">
                  <img src={item?.itemImage} alt={item.itemName} />
                </div>
              ) : (
                <Box
                  sx={{
                    width: 100,
                    height: 100,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "common.white",
                    borderRadius: "8px",
                  }}
                >
                  <ImageNotSupportedIcon fontSize="large"></ImageNotSupportedIcon>

                  <Typography variant="caption">
                    {t("noImage", { ns: "dialog" })}
                  </Typography>
                </Box>
              )}

              <div>
                <Typography className="item-heading" variant="h5">
                  {item.itemName}
                </Typography>
              </div>
            </Box>
          );
        })}
      </Box>

      <div className="btn-navigate">
        <Button className="common-st add-btn" onClick={handleClose}>
          {t("no")}
        </Button>

        <Button
          disabled={removingItem}
          className="common-st go-to-btn"
          onClick={() => clearFunction()}
        >
          {t("yes")}
        </Button>
      </div>
    </Box>
  );
};

export default RemoveFromCart;
