import React from "react";

export const PickupIconLight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
      width="16"
      height="19.25"
      viewBox="0 0 13.549 18"
    >
      <g id="pickup_icon">
        <path
          id="Path_54"
          data-name="Path 54"
          d="M13.486,16.834a.325.325,0,0,0-.325.325v2.206a.225.225,0,0,1-.225.225h-1.81a.225.225,0,0,1-.225-.225V17.159a.325.325,0,0,0-.65,0v2.206a.876.876,0,0,0,.875.875h.58v4.8a.325.325,0,1,0,.65,0v-4.8h.58a.876.876,0,0,0,.875-.875V17.159a.325.325,0,0,0-.325-.325"
          transform="translate(-6.917 -11.361)"
          fill="grey"
        />
        <path
          id="Path_55"
          data-name="Path 55"
          d="M15.051,19.132a.325.325,0,0,0,.325-.325V17.159a.325.325,0,0,0-.65,0v1.648a.325.325,0,0,0,.325.325"
          transform="translate(-9.938 -11.361)"
          fill="grey"
        />
        <path
          id="Path_56"
          data-name="Path 56"
          d="M24.212,16.833H24.12a.325.325,0,0,0-.325.325v7.881a.325.325,0,0,0,.65,0V22.583h1.006a.824.824,0,0,0,.823-.823V18.9a2.064,2.064,0,0,0-2.062-2.062m1.412,4.927a.173.173,0,0,1-.172.172H24.445V17.507A1.411,1.411,0,0,1,25.624,18.9Z"
          transform="translate(-16.058 -11.36)"
          fill="grey"
        />
        <path
          id="Path_57"
          data-name="Path 57"
          d="M11.737,0H1.812A1.814,1.814,0,0,0,0,1.812V16.188A1.814,1.814,0,0,0,1.812,18h9.926a1.814,1.814,0,0,0,1.812-1.812V1.812A1.814,1.814,0,0,0,11.737,0M4.76.65H8.788v.411a.6.6,0,0,1-.6.6H5.356a.6.6,0,0,1-.6-.6ZM12.9,16.188a1.163,1.163,0,0,1-1.161,1.161H1.812A1.163,1.163,0,0,1,.65,16.188V1.812A1.163,1.163,0,0,1,1.812.65h2.3v.411A1.247,1.247,0,0,0,5.356,2.307H8.193A1.247,1.247,0,0,0,9.439,1.061V.65h2.3A1.163,1.163,0,0,1,12.9,1.812Z"
          transform="translate(0 0)"
          fill="grey"
        />
      </g>
    </svg>
  );
};

export const PickupIconDark = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
      width="16"
      height="19.25"
      viewBox="0 0 13.549 18"
    >
      <g id="pickup_icon">
        <path
          id="Path_54"
          data-name="Path 54"
          d="M13.486,16.834a.325.325,0,0,0-.325.325v2.206a.225.225,0,0,1-.225.225h-1.81a.225.225,0,0,1-.225-.225V17.159a.325.325,0,0,0-.65,0v2.206a.876.876,0,0,0,.875.875h.58v4.8a.325.325,0,1,0,.65,0v-4.8h.58a.876.876,0,0,0,.875-.875V17.159a.325.325,0,0,0-.325-.325"
          transform="translate(-6.917 -11.361)"
          fill="#323f4b"
        />
        <path
          id="Path_55"
          data-name="Path 55"
          d="M15.051,19.132a.325.325,0,0,0,.325-.325V17.159a.325.325,0,0,0-.65,0v1.648a.325.325,0,0,0,.325.325"
          transform="translate(-9.938 -11.361)"
          fill="#323f4b"
        />
        <path
          id="Path_56"
          data-name="Path 56"
          d="M24.212,16.833H24.12a.325.325,0,0,0-.325.325v7.881a.325.325,0,0,0,.65,0V22.583h1.006a.824.824,0,0,0,.823-.823V18.9a2.064,2.064,0,0,0-2.062-2.062m1.412,4.927a.173.173,0,0,1-.172.172H24.445V17.507A1.411,1.411,0,0,1,25.624,18.9Z"
          transform="translate(-16.058 -11.36)"
          fill="#323f4b"
        />
        <path
          id="Path_57"
          data-name="Path 57"
          d="M11.737,0H1.812A1.814,1.814,0,0,0,0,1.812V16.188A1.814,1.814,0,0,0,1.812,18h9.926a1.814,1.814,0,0,0,1.812-1.812V1.812A1.814,1.814,0,0,0,11.737,0M4.76.65H8.788v.411a.6.6,0,0,1-.6.6H5.356a.6.6,0,0,1-.6-.6ZM12.9,16.188a1.163,1.163,0,0,1-1.161,1.161H1.812A1.163,1.163,0,0,1,.65,16.188V1.812A1.163,1.163,0,0,1,1.812.65h2.3v.411A1.247,1.247,0,0,0,5.356,2.307H8.193A1.247,1.247,0,0,0,9.439,1.061V.65h2.3A1.163,1.163,0,0,1,12.9,1.812Z"
          transform="translate(0 0)"
          fill="#323f4b"
        />
      </g>
    </svg>
  );
};
