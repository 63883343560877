import React from "react";
import { IoCall } from "react-icons/io5";
import { FaWhatsapp } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { retrunLanguage } from "../../utils/languageSelect";

const ContactUs = () => {
  const { i18n, t } = useTranslation("translation");

  // const phoneNumber = "18185555";
  // let url = `https://web.whatsapp.com/send?phone=${phoneNumber}`;

  // const handleMesssage = () => {
  //   window.open(url);
  // }
  const contactInfo = useSelector((state) => state.auth.country);
  const { customer_care, whatsapp_country_code, whatsapp_no } = contactInfo;

  let url = `https://api.whatsapp.com/send?phone=${whatsapp_country_code}${whatsapp_no}`;

  const handleButtonClick = () => {
    window.open(url);
  };

  return (
    <div className="ordercontact-section">
      <div
        id="call-tag"
        className={retrunLanguage(i18n) ? "call-tag-ar" : "call-tag-en"}
      >
        <IoCall />
        <a href={`tel:${customer_care}`}>{customer_care}</a>
      </div>
      <div
        id="whatsapp-tag"
        className={retrunLanguage(i18n) ? "tag-ar" : "tag-en"}
      >
        <FaWhatsapp />
        <a href="" onClick={() => handleButtonClick("whatsapp")}>
          {t("profile.sendMessage")}
        </a>
      </div>
    </div>
  );
};

export default ContactUs;
