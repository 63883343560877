import React from "react";

import "./style.css";

const AddressTypeButton = ({ type, addressType, onClick, edit, t }) => {
  return (
    <button
      onClick={() => onClick(type)}
      className={
        addressType === type
          ? "active"
          : type === t("apartment")
          ? "apartment"
          : type === t("office")
          ? "office"
          : "house"
      }
      // disabled={edit ? true : false}
    >
      <img
        src={`/assets/img/${
          type === t("apartment")
            ? "appartment"
            : type === t("office")
            ? "office"
            : "house"
        } - ${addressType === type ? "white" : "black"}.svg`}
        alt={type}
      />
      <br />
      <span
        className={
          addressType === type ? "button-text-active" : "button-text-inactive"
        }
      >
        {type.charAt(0).toUpperCase() + type.slice(1)}
      </span>
    </button>
  );
};

const AddressTypeSelector = ({
  addressType,
  handleAddressTypeChange,
  edit,
  t,
}) => (
  <div className="addressButtonContainer">
    {/* <h3>Select Address Type:</h3> */}
    <AddressTypeButton
      type={t("house")}
      addressType={addressType}
      onClick={handleAddressTypeChange}
      edit={edit}
      t={t}
    />
    <AddressTypeButton
      type={t("apartment")}
      addressType={addressType}
      onClick={handleAddressTypeChange}
      edit={edit}
      t={t}
    />
    <AddressTypeButton
      type={t("office")}
      addressType={addressType}
      onClick={handleAddressTypeChange}
      edit={edit}
      t={t}
    />
  </div>
);

export default AddressTypeSelector;
