import React from "react";
import { useTranslation } from "react-i18next";
import "../../screens/order/style.css";
import { useNavigate } from "react-router-dom";
import { paths } from "../../routes/paths";

const OrderIdSection = ({ orderID, orderNo }) => {
  const { t } = useTranslation("order");

  const navigate = useNavigate();

  const handleReceipt = () => {
    navigate(paths.receipt + orderID);
  };

  return (
    <div className="order-id-section">
      <p id="order-id-text">{t("orderId")}</p>
      <div className="orderid-with-tag">
        <p>{orderNo}</p>
        <div id="order-id-tag">
          <a href="" onClick={handleReceipt}>
            {t("viewReciept")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default OrderIdSection;
