import { paths } from "../../routes/paths";


export const handleApiError = (error) => {
  if (error.response) {
    // Handle errors with a response from the server (e.g., non-2xx status codes).
    console.error("Request failed with response:", error.response.data);
    /*Temporary toke expiry solution */
    if (error.response.status === 401) {
      // eslint-disable-next-line no-debugger
      // Clear localStorage and prompt user to log in again for a 401 status code.


      localStorage.clear();
      sessionStorage.removeItem("cartEmpty");

      window.alert("Your session has expired. Please log in again.");
      window.location.href = paths.phone_verification_index;
      // You can also redirect to the login page or perform other actions as needed.
      // Example: navigate('/login');
    }
    throw error.response.data.message;
  } else if (error.message) {
    // Handle other errors (e.g., network issues, invalid token).
    console.error("Request failed with error:", error.message);
    throw new Error("Request failed with error.");
  } else {
    // Handle unexpected errors.
    console.error("Request failed with an unexpected error:", error);
    throw new Error("Request failed with an unexpected error.");
  }
};
