import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import Select from "react-select";

import {
  generateTimeRange,
  isCurrentDateBeforeSavedDate,
  isCurrentTimeBeforeFromTime,
} from "../../../utils/generateTimeArray";
import { retrunLanguage } from "../../../utils/languageSelect";

import { selectStyles } from "./styles";
import "./styles.css";
import { useNavigate } from "react-router-dom";

const ScheduleDialog = (props) => {
  const { t, i18n } = useTranslation("translation");

  const {
    openTimePickerPopup,
    setOpenTimePickerPopup,
    branchId,
    selectedDate,
    previousDate,
    setSelectedDate,
    setPreviousDate,
    navigateTo,
    setPickupFromDialog,
  } = props;

  const navigate = useNavigate();

  const branchHours = useSelector((state) => state.resturants.branchHours);

  const [slotsRange, setSlotRange] = useState([]);
  const [selectedRange, setSelectedRange] = useState(null);

  const getSelectedObject = () => {
    return branchHours
      ?.find((val) => +val.id === +branchId)
      ?.branch_hours?.find((val) => {
        return val.week_day == selectedDate?.format("dddd");
      });
  };

  const getPreviousDayObject = () => {
    return branchHours
      ?.find((val) => +val.id === +branchId)
      ?.branch_hours?.find((val) => {
        return val.week_day == previousDate;
      });
  };

  useEffect(() => {
    if (getSelectedObject()) {
      const formattedRanges = generateTimeRange(
        getSelectedObject().from_time,
        getSelectedObject().to_time
      ).filter((time) => {
        const startAMTime = time.split("-")[0];

        if (startAMTime?.includes("PM")) {
          return time;
        }
      });

      let formattedPreviousDayRange = [];

      if (previousDate !== null) {
        formattedPreviousDayRange = generateTimeRange(
          getPreviousDayObject().from_time,
          getPreviousDayObject().to_time
        ).filter((time) => {
          const startAMTime = time.split("-")[0];
          const endAMTime = time.split("-")[1];

          if (startAMTime?.includes("AM") && endAMTime?.includes("AM")) {
            return time;
          }
        });
      }

      if (formattedPreviousDayRange?.length) {
        setSlotRange([...formattedPreviousDayRange, ...formattedRanges]);
      } else {
        setSlotRange(formattedRanges);
      }
    }
  }, [selectedDate, branchHours]);

  const groupedOptions = slotsRange?.map((time) => {
    const from_time = time.split("-")[0];
    const pickDate = selectedDate?.format("MMM DD, YYYY-dddd");

    return {
      label: `${time}`,
      value: `${time}`,
      isDisabled:
        pickDate &&
        isCurrentTimeBeforeFromTime(from_time) &&
        !isCurrentDateBeforeSavedDate(pickDate)
          ? true
          : false,
    };
  });

  const selectHandler = (selectedOption) => {
    setSelectedRange({
      label: selectedOption.label,
      value: selectedOption.value,
    });
  };

  const closeStates = () => {
    setOpenTimePickerPopup(false);
  };

  useEffect(() => {
    if (!openTimePickerPopup) {
      setTimeout(() => {
        setSelectedDate(null);
        setPreviousDate(null);
        setSelectedRange(null);
      }, 100);
    }
  }, [openTimePickerPopup]);

  return (
    <Dialog
      open={openTimePickerPopup}
      onClose={() => closeStates()}
      sx={{
        margin: "0",
        textAlign: "center",

        "& .MuiDialog-paper": {
          left: "0px !important",
        },
      }}
      className="cart-dialog"
      PaperProps={{
        sx: {
          overflow: "visible",
          margin: retrunLanguage(i18n)
            ? "0px 27px 0px 0px"
            : "0px 0px 0px 27px",
          width: "327px",
          // height: "35%",

          "@media (max-width: 500px)": {
            width: "375px",
            margin: "0px 0px 0px 0px",
          },

          "@media (max-width: 400px)": {
            width: "90%",
          },
        },
      }}
    >
      <DialogTitle id="alert-dialog-title" className="dialog-title title-cart">
        <div
          style={{
            position: "absolute",
            top: "8px",
            right: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => closeStates()}
        >
          <CloseIcon
            sx={{
              width: "24px",
              fontSize: "24px",
              cursor: "pointer",
              color: "#707070 !important",
            }}
          />
        </div>
      </DialogTitle>

      <DialogContent className="dialog-content-cart">
        <div className="">
          {/* <Typography variant="h6" className="date">
            Selected Date: {selectedDate}
          </Typography> */}

          <Select
            placeholder="Select Time Slot"
            value={selectedRange}
            onChange={selectHandler}
            options={groupedOptions}
            styles={selectStyles()}
            // menuIsOpen={true} // Keep the dropdown open
          />
        </div>
      </DialogContent>

      <div className="time-picker">
        <Button
          className="common-st picker-cancel"
          onClick={() => closeStates()}
          sx={{
            marginRight: retrunLanguage(i18n)
              ? "0px !important"
              : "10px !important",
            marginLeft: retrunLanguage(i18n)
              ? "10px !important"
              : "0px !important",
          }}
        >
          {t("cancel", { ns: "dialog" })}
        </Button>

        <Button
          className="common-st picker-save"
          onClick={() => {
            if (selectedRange !== null) {
              const formatDate = selectedDate.format("MMM DD, YYYY-dddd");

              localStorage.setItem("pickDate", formatDate);
              localStorage.setItem("isAsap", false);
              localStorage.setItem("pickTime", selectedRange?.value);

              navigateTo !== null && navigate(navigateTo);
              setPickupFromDialog && setPickupFromDialog(false);

              closeStates();
            }
          }}
          disabled={selectedRange === null}
        >
          {t("save", { ns: "dialog" })}
        </Button>
      </div>
    </Dialog>
  );
};

export default ScheduleDialog;
