export const languageSelector = (i18n) => {
  i18n.changeLanguage(languageChange());
};

export const languageChange = () => {
  const lang = localStorage.getItem("i18nextLng");

  if (lang === "ar") {
    return "en";
  } else {
    return "ar";
  }
};

export const retrunLanguage = (i18n) => {
  if (i18n.language === "ar") {
    return true;
  } else {
    return false;
  }
};

export const orderTypeStatus = (item, t) => {
  switch (item) {
    case "Submitted":
      return t("submitted");
    case "Accepted":
      return t("accepted");
    case "Ready for delivery":
      return t("readyForDispatch");
    case "Ready for pickup":
      return t("readyForPickup");
    case "On The Way":
      return t("onTheWay");
    case "Delivered":
      return t("delivered");
    case "Canceled":
      return t("canceled");
    default:
      return t("accepted");
  }
};

export const orderServiceType = (item, t) => {
  switch (item) {
    case "pickup":
      return t("pickup");
    case "delivery":
      return t("delivery");
    default:
      return t("delivery");
  }
};
