export const selectStyles = () => {
  return {
    option: (provided, state) => ({
      ...provided,
      cursor: "pointer",
      backgroundColor: state.isSelected
        ? "rgba(189,197,209,.3)"
        : "transparent",
      color: state.data.isDisabled ? "gray" : "#000000",
      ":hover": {
        backgroundColor: "rgba(189,197,209,.3)",
      },
    }),
    indicatorsContainer: () => ({
      display: "none",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: "#fafafa",
      border: "1px solid #c0cdd7 !important",
      borderRadius: "12px !important",
      opacity: "1",
      padding: "5px 10px",
      width: "100%",
      marginBottom: "10px",
      color: "#000000",
      boxShadow: "none",
      cursor: "pointer",
    }),
    valueContainer: (baseStyles) => ({
      ...baseStyles,
      paddingLeft: "0px",
    }),
    placeholder: (baseStyles) => ({
      ...baseStyles,
      color: "#959fa8",
      font: "normal normal bold 16px/28px Titillium Web",
    }),
    menu: (provided) => ({
      ...provided,
      color: "#000000",
      borderRadius: "8px",
      marginTop: "0px",
      top: "-10px",
      position: "relative",
    }),
    menuList: (base) => ({
      ...base,
      // scrollbarWidth: "none",
      // "::-webkit-scrollbar": {
      //   display: "none",
      // },
    }),
    group: (base) => ({
      ...base,
      padding: "0px",
    }),
    groupHeading: (base) => ({
      ...base,
      display: "none",
      padding: "0px",
    }),
    input: (provided) => ({
      ...provided,
      opacity: "0",
    }),
  };
};
