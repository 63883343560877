import axiosInstance from "../axiosInstance";
import API_ENDPOINTS from "../endpoints";
import { handleApiError } from "../utils/errorHandling";

export const GET_GATHERING_ITEMS_QUERY_KEY =
  "get_gathering_items_query_key";

const getGatheringItems = async (country_id) => {
  try {
    // Make the GET request to fetch popular items.
    const { data } = await axiosInstance.get(
      API_ENDPOINTS.HOME.GET_ALL_GATHERING_ITEMS + "?country_id="+ country_id +"&currency_id=3"
    );
    return data;
  } catch (error) {
    // Use the handleApiError function for error handling
    handleApiError(error);
  }
};

export default getGatheringItems;
