import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  menuItemScrollId: null,
};

const menuItemListSlice = createSlice({
  name: "menuItemList",
  initialState,
  reducers: {
    setMenuItemScrollId: (state, action) => {
      state.menuItemScrollId = action.payload;
    },
  },
});

export const { setMenuItemScrollId } = menuItemListSlice.actions;

export default menuItemListSlice.reducer;
