import React from "react";

export const DineInIconLight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
      width="25.5"
      height="17.75"
      viewBox="0 0 26.908 18"
    >
      <g id="Dine_In_Icon" clipPath="url(#clip-path)">
        <path
          id="Path_45"
          data-name="Path 45"
          d="M4.725,9.364H.98V.49A.49.49,0,0,0,0,.49v9.854H4.725a1.331,1.331,0,0,1,1.329,1.329V17.51a.49.49,0,0,0,.98,0V11.673A2.312,2.312,0,0,0,4.725,9.364"
          transform="translate(0 0)"
          fill="grey"
        />
        <path
          id="Path_46"
          data-name="Path 46"
          d="M4.347,23.386H0v6.05a.49.49,0,1,0,.98,0v-5.07H4.347a.49.49,0,0,0,0-.98"
          transform="translate(0 -11.926)"
          fill="grey"
        />
        <path
          id="Path_47"
          data-name="Path 47"
          d="M47.1,0a.49.49,0,0,0-.49.49V9.364H42.864a2.312,2.312,0,0,0-2.309,2.309V17.51a.49.49,0,1,0,.98,0V11.673a1.331,1.331,0,0,1,1.329-1.329H47.59V.49A.49.49,0,0,0,47.1,0"
          transform="translate(-20.682 0)"
          fill="grey"
        />
        <path
          id="Path_48"
          data-name="Path 48"
          d="M45.04,23.876a.49.49,0,0,0,.49.49H48.9v5.07a.49.49,0,1,0,.98,0v-6.05H45.53a.49.49,0,0,0-.49.49"
          transform="translate(-22.969 -11.926)"
          fill="grey"
        />
        <path
          id="Path_49"
          data-name="Path 49"
          d="M26.524,8.267a.49.49,0,1,0,0-.98H11.659a.49.49,0,1,0,0,.98H18.6V21.226a.49.49,0,1,0,.98,0V8.267Z"
          transform="translate(-5.696 -3.716)"
          fill="grey"
        />
      </g>
    </svg>
  );
};

export const DineInIconDark = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
      width="25.5"
      height="17.75"
      viewBox="0 0 26.908 18"
    >
      <g id="Dine_In_Icon" clipPath="url(#clip-path)">
        <path
          id="Path_45"
          data-name="Path 45"
          d="M4.725,9.364H.98V.49A.49.49,0,0,0,0,.49v9.854H4.725a1.331,1.331,0,0,1,1.329,1.329V17.51a.49.49,0,0,0,.98,0V11.673A2.312,2.312,0,0,0,4.725,9.364"
          transform="translate(0 0)"
          fill="#323f4b"
        />
        <path
          id="Path_46"
          data-name="Path 46"
          d="M4.347,23.386H0v6.05a.49.49,0,1,0,.98,0v-5.07H4.347a.49.49,0,0,0,0-.98"
          transform="translate(0 -11.926)"
          fill="#323f4b"
        />
        <path
          id="Path_47"
          data-name="Path 47"
          d="M47.1,0a.49.49,0,0,0-.49.49V9.364H42.864a2.312,2.312,0,0,0-2.309,2.309V17.51a.49.49,0,1,0,.98,0V11.673a1.331,1.331,0,0,1,1.329-1.329H47.59V.49A.49.49,0,0,0,47.1,0"
          transform="translate(-20.682 0)"
          fill="#323f4b"
        />
        <path
          id="Path_48"
          data-name="Path 48"
          d="M45.04,23.876a.49.49,0,0,0,.49.49H48.9v5.07a.49.49,0,1,0,.98,0v-6.05H45.53a.49.49,0,0,0-.49.49"
          transform="translate(-22.969 -11.926)"
          fill="#323f4b"
        />
        <path
          id="Path_49"
          data-name="Path 49"
          d="M26.524,8.267a.49.49,0,1,0,0-.98H11.659a.49.49,0,1,0,0,.98H18.6V21.226a.49.49,0,1,0,.98,0V8.267Z"
          transform="translate(-5.696 -3.716)"
          fill="#323f4b"
        />
      </g>
    </svg>
  );
};
