import axiosInstance from "../axiosInstance";
import API_ENDPOINTS from "../endpoints";
import { handleApiError } from "../utils/errorHandling";

const menuItemsAvailability = async (body, token) => {
  try {
    // Make the POST request to perform email login.
    const { data } = await axiosInstance.post(
      API_ENDPOINTS.MENU.MENU_ITEM_AVAILABILITY,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    // Use the handleApiError function for error handling
    handleApiError(error);
  }
};

export default menuItemsAvailability;
