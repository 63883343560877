import axiosInstance from "../axiosInstance";
import API_ENDPOINTS from "../endpoints";
import { handleApiError } from "../utils/errorHandling";

const deliveryChargesByBranch = async () => {
  try {
    // Make the POST request to get all delivery price.
    const { data } = await axiosInstance.get(
      `${API_ENDPOINTS.BRANCH.GET_DELIVERY_CHARGES}/${localStorage.getItem(
        "branchId"
      )}/delivery-charges?country_id=3`
    );
    return data;
  } catch (error) {
    // Use the handleApiError function for error handling
    handleApiError(error);
  }
};

export default deliveryChargesByBranch;
