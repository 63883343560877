import React, { useEffect, useState } from "react";
import ScheduleDialog from "../updatedSchedulePicker";

import dayjs from "dayjs";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  MobileDatePicker,
} from "@mui/x-date-pickers";


const SchedulePicker = (props) => {
  const {
    openDatePicker,
    setOpenDatePicker,

  } = props;

  const [openTimePickerPopup, setOpenTimePickerPopup] = useState(false);

  const [pickDate, setPickDate] = useState(
    dayjs(new Date()).format("MMM DD, YYYY")
  );
  const asapTime = new Date().setMinutes(new Date().getMinutes() + 30);
  const [isAsap, setIsAsap] = useState(true);
  const [pickTime, setPickTime] = useState(dayjs(asapTime).format("hh:mm a"));

  const [selectedDate, setSelectedDate] = useState(null);
  const [valueForDate, setValueForDate] = useState(null);
  const [previousDate, setPreviousDate] = useState(null);


  useEffect(() => {
    if (localStorage.getItem("isAsap") === null) {
      localStorage.setItem("isAsap", isAsap);
    }

    if (localStorage.getItem("pickTime") === null) {
      localStorage.setItem("pickTime", pickTime);
    }

    if (
      localStorage.getItem("pickDate") === null ||
      new Date(localStorage.getItem("pickDate", pickDate)) < new Date()
    ) {
      localStorage.setItem("pickDate", pickDate);
    }
  }, [localStorage]);

  const branchId = localStorage.getItem("branchId");

 
  // Function to check if the previous date is disabled
  const isPreviousDateDisabled = (date) => {
    const currentDate = dayjs(new Date());

    return date < currentDate;
  };

  return (
    <>
      <ScheduleDialog
        openTimePickerPopup={openTimePickerPopup}
        setOpenTimePickerPopup={setOpenTimePickerPopup}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        setPreviousDate={setPreviousDate}
        previousDate={previousDate}
        branchId={branchId}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileDatePicker
          sx={{
            display: "none",
          }}
          open={openDatePicker}
          disablePast
          onChange={(date) => {
            const selectedTime = dayjs(date);
            const beforeDate = selectedTime.subtract(1, "day").format("dddd");

            if (isPreviousDateDisabled(date)) {
              setPreviousDate(null);
            } else {
              setPreviousDate(beforeDate);
            }

            setSelectedDate(date);
            setValueForDate(date);
          }}
          value={valueForDate}
          onAccept={(date) => {
            const selectedTime = dayjs(date);
            const beforeDate = selectedTime.subtract(1, "day").format("dddd");

            if (isPreviousDateDisabled(date)) {
              setPreviousDate(null);
            } else {
              setPreviousDate(beforeDate);
            }

            setSelectedDate(date);
            setValueForDate(date);

            setOpenTimePickerPopup(true);
            setOpenDatePicker(false);
          }}
          onClose={() => {
            setOpenDatePicker(false);

            setTimeout(() => {
              setValueForDate(null);
            }, 100);
          }}
        />
      </LocalizationProvider>
    </>
  );
};

export default SchedulePicker;
