import React from "react";

import "../../screens/order/style.css";

// function formatAddress(input) {
//   if (!input) {
//     return ""; // Return an empty string if the input is undefined or empty
//   }

//   // Split the input string by spaces
//   const parts = input.split(" ");

//   // Group the parts into lines based on certain conditions
//   const lines = [];
//   let currentLine = "";

//   parts.forEach((part) => {
//     // If part includes a colon or a comma, start a new line
//     if (part.includes(":") || part.includes(",")) {
//       if (currentLine !== "") {
//         lines.push(currentLine.trim()); // Remove leading/trailing spaces
//         currentLine = "";
//       }
//     }

//     // Add the part to the current line
//     currentLine += " " + part;
//   });

//   // Push the last line
//   if (currentLine !== "") {
//     lines.push(currentLine.trim()); // Remove leading/trailing spaces
//   }

//   return lines.join("\n");
// }

const Address = ({ address, i18n, serviceType, branchName }) => {
  // const formattedAddress = formatAddress(address);

  return (
    <div className="orderaddress-text">
      {/* <p>Office - Seazen Ardia</p> */}
      <p className="orderformatted-address">
        {serviceType !== "pickup" && (
          <div
            id="location-img"
            className={i18n ? "left-margin" : "right-margin"}
          >
            <img src="/assets/img/location.svg" alt="" />
          </div>
        )}

        <div className="address-details">
          {serviceType === "pickup"
            ? `Pickup order from \n ${branchName}`
            : address?.address_type === "house"
            ? `House: ${address?.house_no}, Block: ${address?.block}, Street: ${address?.street}`
            : address?.address_type === "office"
            ? `Company: ${address?.company_name}, Block: ${address?.block}, Floor: ${address?.floor}`
            : `Apartment: ${address?.apartment_no}, Block: ${address?.block}, Street: ${address?.street}`}
        </div>
      </p>
    </div>
  );
};

export default Address;
