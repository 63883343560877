import React, { useEffect, useRef, useState } from "react";
import {
  // useSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import { Box, Stack, Typography, Modal, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { Button } from "react-bootstrap";

import MenuCard from "../menu_card/index.js";
import { DialogComponent } from "../../../dialog/index.js";
import SelectLocation from "../../../dialog/selectLocation.js";
import SchedulePicker from "../../../shared/schedulePicker/index.js";
import ScheduleDialog from "../../../../components/dialog/scheduleDialog.js";
import RemoveFromCart from "../../../dialog/differentBranchRestaurant.js";

import { retrunLanguage } from "../../../../utils/languageSelect.js";

import { paths } from "../../../../routes/paths.jsx";

import {
  removeFromCart,
  resetCart,
} from "../../../../store/reducers/cartSlice.js";
import {
  getCategoryItemList,
  getCategoryList,
  // getBranchHours,
  // getNearByRest,
  // setBranchID,
  setCategoryElementID,
  toggleCategoryListLoading,
} from "../../../../store/reducers/resturantsSlice.js";
// import { addSelectedAddress } from "../../../../store/reducers/userLocationSlice.js";

import {
  // getDistanceFromLatLonInKm,
  getFullAddress,
  selectChooseAddress,
} from "../../../../utils/getAddress.js";

// import getRestaurantBranchHours from "../../../../api/restaurant/getRestaurantBranchHours.js";
// import getNearByRestaurants from "../../../../api/restaurant/getNearByRestaurant.js";
// import getBranchByArea from "../../../../api/restaurant/branches/getBranchByArea.js";
import getBranchByAreaId from "../../../../api/restaurant/branches/getBranchIdBasedOnAreaId.js";
import menuItemsAvailability from "../../../../api/menu/menuItemsAvailability.js";
import deleteCart from "../../../../api/cart/deleteCartItem.js";

import "./style.css";
import PickupFrom from "../../../dialog/pickupDialog.js";
import { addSelectedAddress } from "../../../../store/reducers/userLocationSlice.js";
import getListItemByCategory from "../../../../api/restaurant/getListItemsByCategory.js";
import getItemCategoryList from "../../../../api/restaurant/getItemCategoryList.js";
import { viewItemListScript } from "../../../../utils/conversionScripts.js";

const MenuCardListContainer = ({
  itemCategoryList,
  locationDeliver,
  restaurantBranchHours,
  resturantId,
  restaurantDetail,
  setOpenModal,
  setSelectedItemToDeliver,
  // setNotDeliver,
  // notDeliver,
  ItemsCategoryLoading,
  branchId,
  value,
  setBranchName,
  typeStatus,
  setPickupFromDialog,
  setAddressSelected,
  setSelectAddressNotDeliver,
}) => {
  const { i18n, t } = useTranslation();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const cartTotalItems = useSelector((state) => state.cart.totalQuantity);
  const categoryList = useSelector(
    (state) => state?.resturants?.listItemByCategory
  );
  const cart = useSelector((state) => state.cart.cart);
  const addressData = useSelector((state) => state.address.addressData);

  const allBranches = useSelector((state) => state.resturants.allBranches);
  const resturantsData = useSelector(
    (state) => state.resturants.resturantsData
  );

  const cartTotal = cart.reduce(
    (total, item) => total + Number(item.totalPrice),
    0
  );

  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [openAddressPopup, setOpenAddressPopup] = useState(false);
  const [openSchedulePopup, setOpenSchedulePopup] = useState(false);
  const [showTimePicker, setShowTimePicker] = useState(false);
  const [restaurantIsOpen, setRestaurantIsOpen] = useState(false);
  // const [showList, setShowList] = useState([]);
  const [fullAddress, setFullAddress] = useState(null);
  const [addressID, setAddressID] = useState();
  const [delivery_charges, setDeliveryCharge] = useState();
  const [delivery_time, setDeliveryTime] = useState();
  const [name_en, setNameEn] = useState();
  const [restName, setRestName] = useState();
  const [clearCartModal, setClearCartModal] = useState(false);
  const [removingItem, setRemovingItem] = useState(false);
  const [changeAddressDetails, setChangeAddressDetails] = useState(null);
  const [itemsAfterRemove, setItemsAfterRemove] = useState([]);
  const [dispatchCount, setDispatchCount] = useState(0);
  const [menuItemsVariances, setMenuItemsVariances] = useState([]);
  const [listState, setListState] = useState([]);
  const hasScriptRun = useRef(false);
  const currentLocation = JSON.parse(localStorage.getItem("location"));
  const isAfterNewLocation = JSON.parse(
    sessionStorage?.getItem("storeAddress")
  );
  const token = localStorage?.getItem("token");
  const scrollToId = useSelector(
    (state) => state.menuItemList?.menuItemScrollId
  );
  const { id } = useParams();
  // Step 1: Create a useRef to store item references
  const itemRefs = useRef({});

  const addRestauantName = (id) => {
    const restName = resturantsData?.find((rest) => rest.id === id);

    return restName?.name_en;
  };

  useEffect(() => {
    if (cartTotalItems === 0) {
      dispatch(resetCart());
    }
  }, [cartTotalItems]);

  // useEffect(() => {
  //   setShowList(categoryList?.slice(0, 10));
  // }, [categoryList]);

  const toggleSearch = () => {
    setOpen(true);
    setIsSearchOpen(!isSearchOpen);
  };

  const handleClose = () => {
    setOpen(false);
    setIsSearchOpen(!isSearchOpen);
  };

  const getMenuItemsVariances = () => {
    let menuItemsVariances = [];

    // for each item, get the sideVariant, sizeVariant and toppingVariant
    cart.forEach((element) => {
      if (element?.variants) {
        const { sideVariant, sizeVariant, toppingVariant, customVariant } =
          element.variants;
        let menuItemVariant = {
          id: element.id,
          itemName: element.itemName,
          item_code: element.item_code,
          quantity: element.quantity,
          has_variants: element.has_variants,
          sideVariants: sideVariant.map((variant) => {
            return {
              name_en: variant?.name_en,
              quantity: variant.quantity,
            };
          }),
          sizeVariant: {
            name_en: sizeVariant?.name_en,
            quantity: sizeVariant?.quantity,
          },
          toppingVariants: toppingVariant?.map((variant) => {
            return {
              name_en: variant?.name_en,
              quantity: variant?.quantity,
            };
          }),
          customVariants: customVariant?.map((variant) => {
            return {
              name_en: variant?.name_en,
              quantity: variant?.quantity,
            };
          }),
        };

        /* has variants was changing to false after updating cart */
        // if (
        //   menuItemVariant?.customVariants?.length > 0 ||
        //   menuItemVariant.has_variants
        // ) {
        //   menuItemsVariances.push(menuItemVariant);
        // }

        menuItemsVariances.push(menuItemVariant);
      }
    });

    setMenuItemsVariances(menuItemsVariances);

    return menuItemsVariances;
  };

  useEffect(() => {
    getMenuItemsVariances();
  }, [cart]);
  useEffect(() => {
    const options = {
      root: null, // Use the viewport as the root
      rootMargin: "-300px",
      threshold: [0, 0.25, 0.5, 0.75, 1],
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const elementId = entry.target.id;
          dispatch(setCategoryElementID(elementId));
          // You can perform any action here with the element ID
        }
      });
    }, options);

    // You can select the elements you want to observe by their class or other attributes.
    const elements = document.querySelectorAll(".card_list_title");
    elements.forEach((element) => {
      observer.observe(element);
    });

    // Cleanup the observer when the component unmounts
    return () => observer.disconnect();
  }, [categoryList, listState]);

  let categoriesWiseList = {};

  categoryList?.length &&
    categoryList?.forEach(({ category_id }) => {
      return (categoriesWiseList[category_id] = categoryList?.filter(
        (val) => val.category_id == category_id
      ));
    });

  useEffect(() => {
    // Filter objects based on whether their id is in the ids array
    const filteredObjs = itemCategoryList?.filter((obj) =>
      Object.keys(categoriesWiseList).includes(obj.id.toString())
    );

    setListState(filteredObjs);
  }, [categoryList]);

  const filterItems = (query) => {
    const lowercasedQuery = query.toLowerCase();
    const filtered = categoryList.filter((item) => {
      const itemName = item?.name_en.toLowerCase();
      const itemIngredients = item.description_en?.toLowerCase();
      return (
        itemName.includes(lowercasedQuery) ||
        itemIngredients?.includes(lowercasedQuery)
      );
    });
    setFilteredItems(filtered);
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    if (query === "") {
      setFilteredItems([]);
      return;
    }
    filterItems(query);
  };

  const fetchAllCategories = (restaurant_id) => {
    dispatch(toggleCategoryListLoading());

    getItemCategoryList(restaurant_id)
      .then((res) => {
        dispatch(getCategoryList(res?.data));
      })
      .catch((e) => console.error(e));
  };

  const fetchAllItems = (branch_id, restaurant_id) => {
    getListItemByCategory(
      restaurant_id,
      branch_id,
      searchParams.get("category"),
      JSON.parse(localStorage.getItem("country"))?.id
    )
      .then((res) => {
        dispatch(getCategoryItemList(res?.data));
      })
      .catch((e) => console.error(e));
  };

  const changeAddressAfterSelect = (item) => {
    getBranchByAreaId(
      restaurantDetail?.id,
      JSON.parse(localStorage.getItem("country"))?.id,
      item.delivery_charge_area_name_en,
      dispatch
    )
      .then((res) => {
        if (Array.isArray(res.data)) {
          setOpenAddressPopup(false);
          setSelectAddressNotDeliver(true);

          return;
        }

        setSelectAddressNotDeliver(false);
        selectChooseAddress(
          item,
          setAddressID,
          setDeliveryCharge,
          setDeliveryTime,
          // setMinOrderAmount,
          setNameEn,
          setRestName,
          getFullAddress,
          setFullAddress,
          addRestauantName
        );

        fetchAllCategories(restaurantDetail?.id);
        fetchAllItems(res.data.branch_id, restaurantDetail?.id);
      })
      .catch((err) => {
        // getUserAddress(address.latitude, address.longitude);
        // setAddressID(address.id);
        setOpenAddressPopup(false);

        console.log(err);
      });

    // getBranchByArea(restaurantDetail?.id, object, dispatch)
    //   .then((res) => {
    //     if (res?.data?.length === 0) {
    //       getBranchIdNearToLocation();
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  const chooseAddress = (item) => {
    // const object = {
    //   country_id: JSON.parse(localStorage.getItem("country"))?.id,
    //   delivery_charge_area_id: item?.delivery_charge_area_info.id,
    // };

    if (cart?.length > 0) {
      const itemIds = cart?.map((item) => {
        return item.id;
      });

      const availabilityObject = {
        restaurant_id: item.delivery_charge_area_info.restaurant_id,
        branch_id: item.delivery_charge_area_info.branch_id,
        item_ids: itemIds,
      };

      menuItemsAvailability(availabilityObject, token).then((itemRes) => {
        if (itemRes?.data?.unavailable_item_ids?.length > 0) {
          const removeFromCart = cart?.filter((item) =>
            itemRes?.data?.unavailable_item_ids.includes(item.id)
          );

          setItemsAfterRemove(removeFromCart);

          setClearCartModal(true);
          setChangeAddressDetails({
            address: item,
          });
        } else {
          changeAddressAfterSelect(item);
        }
      });
    } else {
      changeAddressAfterSelect(item);
    }
  };

  const clearFunction = () => {
    setRemovingItem(true);

    itemsAfterRemove?.map((item) => {
      deleteCart(
        {
          cart_id: item.cart_id,
          item_id: item.id,
          item_code: item.item_code,
        },
        token
      )
        .then(() => {
          dispatch(removeFromCart(item.id));

          setDispatchCount((prevCount) => prevCount + 1);
        })
        .catch(() => {
          setRemovingItem(false);
        });
    });
  };

  useEffect(() => {
    if (
      dispatchCount === itemsAfterRemove?.length &&
      changeAddressDetails?.address !== undefined
    ) {
      setRemovingItem(false);

      changeAddressAfterSelect(changeAddressDetails?.address);

      setClearCartModal(false);
    }
  }, [dispatchCount, itemsAfterRemove]);

  const navigateUser = () => {
    navigate(paths.location, {
      state: {
        path: "menu/restaurant",
        restaurant_id: id,
        restaurantName: restaurantDetail?.name_en,
        addNew: true,
      },
    });
  };

  const returnBranchName = (brName) => {
    const branch = allBranches
      ?.filter((branch) => branch?.restaurant_id === cart[0]?.restaurant_id)
      ?.some((branch) =>
        retrunLanguage(i18n)
          ? branch.name_ar === brName
          : branch.name_en === brName
      );

    return branch;
  };

  useEffect(() => {
    /* this function is using setAddressSelected to reflect change in delivery
    dropdown in deliveryDetails component*/
    if (fullAddress !== null) {
      const addToStore = {
        ...fullAddress,
        addressId: addressID,
        delivery_charges,
        delivery_time,
        // minOrderAmount,
        name_en,
        restaurantName: restName,
      };

      localStorage.setItem("selected", JSON.stringify(addToStore));
      dispatch(addSelectedAddress(addToStore));
      setAddressSelected(fullAddress);

      setOpenAddressPopup(false);
      setFullAddress(null);
    }
  }, [fullAddress]);

  const handleCartClick = () => {
    localStorage.getItem("token") === null
      ? navigate(paths.cart)
      : isAfterNewLocation !== null &&
        isAfterNewLocation?.addNewAddress === true &&
        localStorage.getItem("selected") === null &&
        cart?.length > 0 &&
        typeStatus === "delivery"
      ? navigate(paths.address, {
          state: {
            latlng: {
              lat: currentLocation?.lat,
              lng: currentLocation?.lng,
            },
            name: currentLocation?.address,
            path: "menu/restaurant",
            restaurant_id: id,
          },
        })
      : addressData?.length === 0 && cart.length && typeStatus === "delivery"
      ? navigate(paths.location, {
          state: {
            noAddress: true,
            latlng: {
              lat: currentLocation?.lat,
              lng: currentLocation?.lng,
            },
            name: currentLocation?.address,
            path: "menu/restaurant",
            restaurant_id: id,
          },
        })
      : addressData?.length > 0 &&
        localStorage.getItem("selected") === null &&
        cart.length > 0 &&
        typeStatus === "delivery"
      ? setOpenAddressPopup(true)
      : cart?.length > 0 &&
        typeStatus &&
        typeStatus === "pickup" &&
        (localStorage.getItem("branchName") === null ||
          returnBranchName(localStorage.getItem("branchName")) === false)
      ? setPickupFromDialog(true)
      : navigate(paths.cart, {
          state: {
            fromMenu: true,
            path: "menu/restaurant",
            restaurant_id: cart[0]?.restaurant_id,
          },
        });
  };

  useEffect(() => {
    if (!hasScriptRun.current && categoryList.length) {
      const restaurantName = restaurantDetail?.name_en;
      viewItemListScript(categoryList, restaurantName, typeStatus);
      hasScriptRun.current = true;
    }
  }, [categoryList]);

  useEffect(() => {
    if (scrollToId && itemRefs.current[scrollToId]) {
      itemRefs.current[scrollToId].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [ItemsCategoryLoading, scrollToId]);

  return (
    <div>
      <div>
        <div className="menu_card_list_outer_container px-3 px-sm-4 pt-4 pb-5">
          <div className="menu_card_list_inner_container">
            <>
              {ItemsCategoryLoading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "70px !important",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Stack>
                  {categoryList && categoryList?.length > 0 && (
                    <div className="searchwrapper">
                      <img
                        src="/assets/img/Icon - Search.svg"
                        alt="search_icon"
                        className="searchicon"
                        onClick={toggleSearch}
                      />
                    </div>
                  )}
                  {listState?.length &&
                    listState?.map((val, index) => {
                      return (
                        <>
                          <div
                            id={index + 1}
                            className="card_list_title"
                            style={{
                              marginTop: index == 0 ? "-3px" : "16px",
                              height: "27px",
                            }}
                          >
                            {retrunLanguage(i18n) ? val?.name_ar : val?.name_en}
                          </div>{" "}
                          {categoriesWiseList[val?.id]
                            ?.sort(
                              (menuItemsA, menuItemsB) =>
                                menuItemsA.display_order -
                                menuItemsB.display_order
                            )
                            ?.map((item, index) => {
                              if (item.branch_status) {
                                return (
                                  <div
                                    key={item.id}
                                    ref={(el) =>
                                      (itemRefs.current[item.id] = el)
                                    } // Step 3: Assign ref to each item
                                  >
                                    <MenuCard
                                      allData={item}
                                      key={index}
                                      locationDeliver={locationDeliver}
                                      resturantId={resturantId}
                                      restaurantBranchHours={restaurantBranchHours?.data?.filter(
                                        (rest) => rest?.id === branchId
                                      )}
                                      restaurantDetail={restaurantDetail}
                                      foodItem={
                                        retrunLanguage(i18n)
                                          ? item?.name_ar
                                          : item?.name_en
                                      }
                                      foodIngredients={
                                        retrunLanguage(i18n)
                                          ? item.description_ar
                                          : item.description_en
                                      }
                                      foodPrice={item.price}
                                      foodImage={item.image}
                                      id={item.id}
                                      restaurant_id={item.restaurant_id}
                                      showSubTractButton={
                                        item.has_extras === false &&
                                        item.has_variants === false &&
                                        item.has_options === false
                                      }
                                      setOpenModal={setOpenModal}
                                      setSelectedItemToDeliver={
                                        setSelectedItemToDeliver
                                      }
                                      setRestaurantIsOpen={setRestaurantIsOpen}
                                      menuItemsVariances={menuItemsVariances.filter(
                                        (varianceItem) =>
                                          varianceItem.id === item.id
                                      )}
                                      typeStatus={typeStatus}
                                      showLabel={
                                        item.is_new || item.is_top_pick
                                          ? true
                                          : false
                                      }
                                    />
                                  </div>
                                );
                              }
                            })}
                        </>
                      );
                    })}
                </Stack>
              )}
            </>

            {/* <div className="comment_button_container">
          <button>
            <img src="/assets/img/comment_icon.svg" alt="comment_icon" />
          </button>
        </div> */}
          </div>
        </div>

        <Box
          sx={{
            position: "fixed",
            cursor: "pointer",
            bottom: 0,
            px: 2,
            py: 1.5,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            maxWidth: "375px",
            borderTopLeftRadius: (theme) => theme.spacing(2),
            borderTopRightRadius: (theme) => theme.spacing(2),
            backgroundColor: (theme) => theme.palette.themeColor.main,
            "@media (max-width: 500px)": {
              maxWidth: "100%",
            },
          }}
          onClick={handleCartClick}
        >
          <Stack direction="row" alignItems="center" spacing={1.5}>
            <Box
              sx={{
                py: 0.5,
                px: 2,
                borderRadius: 1,
                backgroundColor: "#ffffff24",
              }}
            >
              {cartTotalItems}
            </Box>
            <Typography
              sx={{
                marginLeft: retrunLanguage(i18n) ? "0px !important" : "12px",
                marginRight: retrunLanguage(i18n) ? "12px !important" : "0px",
              }}
            >
              {t("heading", { ns: "cart" })}
            </Typography>
          </Stack>

          <Box
            sx={{
              py: 0.5,
              px: 2,
              borderRadius: 1,
              backgroundColor: "#ffffff24",
              display: retrunLanguage(i18n) ? "flex" : "block",
            }}
          >
            <span
              style={{
                order: 0,
                margin: retrunLanguage(i18n) ? "0px 0px 0px 5px" : "0px",
              }}
            >
              {t("kwd", { ns: "common" })}{" "}
            </span>
            <span>{Number(cartTotal).toFixed(3) || Number(0).toFixed(3)}</span>
          </Box>
        </Box>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          border: "none",
          overflowY: "hidden",
        }}
      >
        <div className="Modal_Search_Item" tabIndex="">
          <div className="closebtnmodal">
            <Button className="closebtn">
              <CloseIcon sx={{ color: "#000" }} onClick={handleClose} />
            </Button>
          </div>
          <div className="InputSearchModal">
            <input
              type="text"
              className="InputModal"
              placeholder={
                retrunLanguage(i18n) ? t("search", { ns: "common" }) : "Search"
              }
              value={searchQuery}
              onChange={(event) => handleSearchChange(event)}
            />
          </div>

          <div className="list-menu-cards">
            {filteredItems
              ?.sort(
                (menuItemsA, menuItemsB) =>
                  menuItemsA.display_order - menuItemsB.display_order
              )
              ?.map((item, index) => {
                if (item?.branch_status) {
                  return (
                    <div className="categoryItemsList" key={index + 1}>
                      <MenuCard
                        allData={item}
                        key={index}
                        locationDeliver={locationDeliver}
                        resturantId={resturantId}
                        restaurantBranchHours={restaurantBranchHours?.data?.filter(
                          (rest) => rest?.id === branchId
                        )}
                        restaurantDetail={restaurantDetail}
                        foodItem={item?.name_en}
                        foodIngredients={item.description_en}
                        foodPrice={item.price}
                        foodImage={item.image}
                        id={item.id}
                        restaurant_id={item.restaurant_id}
                        showSubTractButton={
                          item.has_extras === false &&
                          item.has_variants === false &&
                          item.has_options === false
                        }
                        setOpenModal={setOpenModal}
                        setSelectedItemToDeliver={setSelectedItemToDeliver}
                        setRestaurantIsOpen={setRestaurantIsOpen}
                        menuItemsVariances={menuItemsVariances.filter(
                          (varianceItem) => varianceItem.id === item.id
                        )}
                        typeStatus={typeStatus}
                      />
                    </div>
                  );
                }
              })}
          </div>
        </div>
      </Modal>

      <SelectLocation
        openAddressPopup={openAddressPopup}
        handleClose={() => setOpenAddressPopup(false)}
        chooseAddress={chooseAddress}
        navigateUser={navigateUser}
      />

      {typeStatus === "delivery" && (
        <DialogComponent
          // notDeliver={notDeliver}
          // setNotDeliver={setNotDeliver}
          restaurantName={restName}
          fromDetail={true}
          resturantId={id}
          location={location}
          home={true}
          menuCard={true}
        />
      )}

      <SchedulePicker
        openDatePicker={openSchedulePopup}
        setOpenDatePicker={setOpenSchedulePopup}
        showTimePicker={showTimePicker}
        setShowTimePicker={setShowTimePicker}
        menuCard={true}
      />

      <ScheduleDialog
        restaurantOpen={restaurantIsOpen}
        setRestaurantOpen={setRestaurantIsOpen}
        setOpenSchedulePopup={setOpenSchedulePopup}
        menuCard={true}
      />

      <RemoveFromCart
        clearCartModal={clearCartModal}
        setClearCartModal={setClearCartModal}
        textToShow={t("branchChangeWraning", { ns: "dialog" })}
        clearFunction={clearFunction}
        itemsAfterRemove={itemsAfterRemove}
        removingItem={removingItem}
      />

      {/* <PickupFrom
        pickupFromDialog={pickupFromDialog}
        handleClose={() => setPickupFromDialog(false)}
        branchHours={branchHours?.filter((branch) => branch.is_display === "1")}
        restaurant_logo={restaurantDetail?.logo}
        setBranchName={setBranchName}
      /> */}
    </div>
  );
};

export default MenuCardListContainer;
