import React from "react";
import Structure from "../structure";
import { useTranslation } from "react-i18next";

function Information() {
  const { t } = useTranslation("translation");

  const user = {
    name: t("guest.guest"),
    register: t("guest.registerToStart"),
    imageUrl: "/assets/img/guest-profile-image.svg",
  };

  const handleEditClick = () => {
    // Handle edit button click here
  };

  return (
    <div>
      <Structure
        name={user.name}
        register={user.register}
        imageUrl={user.imageUrl}
        onEditClick={handleEditClick}
      />
    </div>
  );
}

export default Information;
