import axiosInstance from "../axiosInstance";
import API_ENDPOINTS from "../endpoints";
import { handleApiError } from "../utils/errorHandling";

const orderConversion = async ({
  order_id,
  reference_no,
  purchase_event_fired,
}) => {
  try {
    // Make the POST request to update order conversion.
    const { data } = await axiosInstance.post(
      `${API_ENDPOINTS.ORDER.ORDER_CONVERSION}/${order_id}`,
      {
        order_id,
        reference_no,
        purchase_event_fired,
      }
    );
    return data;
  } catch (error) {
    // Use the handleApiError function for error handling
    handleApiError(error);
  }
};

export default orderConversion;
