import React, { useEffect, useRef } from "react";
import IconPositionTabs from "../tabComponent/index.js";
import { useState } from "react";
import CateringDetails from "../../restaurant/catering/index.js";
import DineInCoditionalComponent from "../../restaurant/dineIn/dineInConditionalComponent/index.js";
import PickupConditionalComponent from "../../restaurant/pickup/pickupConditionalComponent/index.js";
import DeliveryConditionalComponent from "../../restaurant/delivery/deliveryDetails/deliveryConditionalComponent/index.js";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

import getListItemByCategory from "../../../api/restaurant/getListItemsByCategory"; // GET_CATEGORY_LIST_ITEM,
import getItemCategoryList from "../../../api/restaurant/getItemCategoryList"; // GET_ITEM_CATEGORY_LIST,

import {
  getCategoryItemList,
  getCategoryList,
  toggleCategoryListLoading,
} from "../../../store/reducers/resturantsSlice";

const TabsDynamicConditionalComponent = ({
  resturantId,
  restaurantBranchHours,
  restaurantDetail,
  setOpenModal,
  setSelectedItemToDeliver,
  scheduleForLater,
  fromDetail,
  setFromDetail,
  setNotDeliver,
  setRestaurantOpen,
  setOrderAmFromDelivery,
  branchIdState,
  pickupFromDialog,
  setPickupFromDialog,
}) => {
  const { t } = useTranslation("translation");

  const [searchParams] = useSearchParams();
  const { option } = useParams();

  const dispatch = useDispatch();
  const selectedService = useSelector(
    (state) => state.resturants.selectedService
  );
  const country = useSelector((state) => state.auth.country);
  const categoryList = useSelector(
    (state) => state?.resturants?.listItemByCategory
  );

  const onlyPickup = () => {
    if (
      (!restaurantDetail.delivery_status && restaurantDetail.pickup_status) ||
      option === "pickup"
    ) {
      return t("pickup", { ns: "landingPage" }).toLowerCase();
    } else {
      return t("delivery", { ns: "landingPage" }).toLowerCase();
    }
  };

  const [tabPosition, setTabPosition] = useState(
    selectedService ? selectedService : onlyPickup()
  );
  const [value, setValue] = React.useState(0);

  const [locationDeliver, setLocationDeliver] = useState(false);
  const [deliveryAvailable, setDeliveryAvailable] = useState(false);
  const ranOnceRef = useRef(false);

  const fetchAllItems = () => {
    if (localStorage.getItem("branchId") != null) {
      dispatch(toggleCategoryListLoading());

      getListItemByCategory(
        resturantId,
        localStorage.getItem("branchId"),
        searchParams.get("category"),
        country?.id
      )
        .then((res) => {
          dispatch(getCategoryItemList(res?.data));
        })
        .catch((e) => console.error(e));
    }
  };

  const fetchAllCategories = () => {
    getItemCategoryList(resturantId)
      .then((res) => {
        dispatch(getCategoryList(res?.data));
      })
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    if (!ranOnceRef.current && categoryList[0]?.branch_id !== branchIdState) {
      fetchAllCategories();
      fetchAllItems();
      ranOnceRef.current = true;
    }
  }, [branchIdState]);

  const getDefaultService = () => {
    if (restaurantDetail?.dinein_status) {
      return <DineInCoditionalComponent />;
    }
    if (restaurantDetail?.catering_status) {
      return <CateringDetails />;
    }
    if (restaurantDetail?.delivery_status) {
      return (
        <DeliveryConditionalComponent
          locationDeliver={locationDeliver}
          setLocationDeliver={setLocationDeliver}
          deliveryAvailable={deliveryAvailable}
          setDeliveryAvailable={setDeliveryAvailable}
          errorMessage={t("customError.noDeliver")}
          resturantId={resturantId}
          restaurantBranchHours={restaurantBranchHours}
          restaurantDetail={restaurantDetail}
          setOpenModal={setOpenModal}
          setSelectedItemToDeliver={setSelectedItemToDeliver}
          scheduleForLater={scheduleForLater}
          fromDetail={fromDetail}
          setFromDetail={setFromDetail}
          setNotDeliver={setNotDeliver}
          setRestaurantOpen={setRestaurantOpen}
          setOrderAmFromDelivery={setOrderAmFromDelivery}
          branchIdState={branchIdState}
        />
      );
    }
    if (restaurantDetail?.pickup_status) {
      return (
        <PickupConditionalComponent
          locationDeliver={locationDeliver}
          setLocationDeliver={setLocationDeliver}
          deliveryAvailable={deliveryAvailable}
          setDeliveryAvailable={setDeliveryAvailable}
          errorMessage={t("customError.noDeliver")}
          resturantId={resturantId}
          restaurantBranchHours={restaurantBranchHours}
          restaurantDetail={restaurantDetail}
          setOpenModal={setOpenModal}
          setSelectedItemToDeliver={setSelectedItemToDeliver}
          scheduleForLater={scheduleForLater}
          fromDetail={fromDetail}
          setFromDetail={setFromDetail}
          setNotDeliver={setNotDeliver}
          setRestaurantOpen={setRestaurantOpen}
          setOrderAmFromDelivery={setOrderAmFromDelivery}
          branchIdState={branchIdState}
          value={value}
          pickupFromDialog={pickupFromDialog}
          setPickupFromDialog={setPickupFromDialog}
        />
      );
    }
  };

  switch (tabPosition) {
    case "dinein":
      return (
        <div>
          <IconPositionTabs
            isDineIn={restaurantDetail?.dinein_status}
            isCatering={restaurantDetail?.catering_status}
            isDelivery={restaurantDetail?.delivery_status}
            isPickup={restaurantDetail?.pickup_status}
            tabPosition={tabPosition}
            setTabPosition={setTabPosition}
            locationDeliver={locationDeliver}
            setLocationDeliver={setLocationDeliver}
            deliveryAvailable={deliveryAvailable}
            setDeliveryAvailable={setDeliveryAvailable}
            resturantId={resturantId}
            value={value}
            setValue={setValue}
          />
          <DineInCoditionalComponent />
        </div>
      );
    case "catering":
      return (
        <div>
          <IconPositionTabs
            isDineIn={restaurantDetail?.dinein_status}
            isCatering={restaurantDetail?.catering_status}
            isDelivery={restaurantDetail?.delivery_status}
            isPickup={restaurantDetail?.pickup_status}
            tabPosition={tabPosition}
            setTabPosition={setTabPosition}
            locationDeliver={locationDeliver}
            setLocationDeliver={setLocationDeliver}
            deliveryAvailable={deliveryAvailable}
            setDeliveryAvailable={setDeliveryAvailable}
            resturantId={resturantId}
            value={value}
            setValue={setValue}
          />
          <CateringDetails />
        </div>
      );
    case "delivery":
      return (
        <div>
          <IconPositionTabs
            isDineIn={restaurantDetail?.dinein_status}
            isCatering={restaurantDetail?.catering_status}
            isDelivery={restaurantDetail?.delivery_status}
            isPickup={restaurantDetail?.pickup_status}
            tabPosition={tabPosition}
            setTabPosition={setTabPosition}
            locationDeliver={locationDeliver}
            setLocationDeliver={setLocationDeliver}
            deliveryAvailable={deliveryAvailable}
            setDeliveryAvailable={setDeliveryAvailable}
            resturantId={resturantId}
            value={value}
            setValue={setValue}
          />
          <DeliveryConditionalComponent
            locationDeliver={locationDeliver}
            setLocationDeliver={setLocationDeliver}
            deliveryAvailable={deliveryAvailable}
            setDeliveryAvailable={setDeliveryAvailable}
            errorMessage={t("customError.noDeliver")}
            resturantId={resturantId}
            restaurantBranchHours={restaurantBranchHours}
            restaurantDetail={restaurantDetail}
            setOpenModal={setOpenModal}
            setSelectedItemToDeliver={setSelectedItemToDeliver}
            scheduleForLater={scheduleForLater}
            fromDetail={fromDetail}
            setFromDetail={setFromDetail}
            setNotDeliver={setNotDeliver}
            setRestaurantOpen={setRestaurantOpen}
            onDelivery={true}
            setOrderAmFromDelivery={setOrderAmFromDelivery}
            branchIdState={branchIdState}
          />
        </div>
      );
    case "pickup":
      return (
        <div>
          <IconPositionTabs
            isDineIn={restaurantDetail?.dinein_status}
            isCatering={restaurantDetail?.catering_status}
            isDelivery={restaurantDetail?.delivery_status}
            isPickup={restaurantDetail?.pickup_status}
            tabPosition={tabPosition}
            setTabPosition={setTabPosition}
            locationDeliver={locationDeliver}
            setLocationDeliver={setLocationDeliver}
            deliveryAvailable={deliveryAvailable}
            setDeliveryAvailable={setDeliveryAvailable}
            resturantId={resturantId}
            value={value}
            setValue={setValue}
          />
          <PickupConditionalComponent
            locationDeliver={locationDeliver}
            setLocationDeliver={setLocationDeliver}
            deliveryAvailable={deliveryAvailable}
            setDeliveryAvailable={setDeliveryAvailable}
            errorMessage={t("customError.noDeliver")}
            resturantId={resturantId}
            restaurantBranchHours={restaurantBranchHours}
            restaurantDetail={restaurantDetail}
            setOpenModal={setOpenModal}
            setSelectedItemToDeliver={setSelectedItemToDeliver}
            scheduleForLater={scheduleForLater}
            fromDetail={fromDetail}
            setFromDetail={setFromDetail}
            setNotDeliver={setNotDeliver}
            setRestaurantOpen={setRestaurantOpen}
            setOrderAmFromDelivery={setOrderAmFromDelivery}
            branchIdState={branchIdState}
            value={value}
            pickupFromDialog={pickupFromDialog}
            setPickupFromDialog={setPickupFromDialog}
          />
        </div>
      );
    default:
      return (
        <div>
          <IconPositionTabs
            isDineIn={restaurantDetail?.dinein_status}
            isCatering={restaurantDetail?.catering_status}
            isDelivery={restaurantDetail?.delivery_status}
            isPickup={restaurantDetail?.pickup_status}
            tabPosition={tabPosition}
            setTabPosition={setTabPosition}
            locationDeliver={locationDeliver}
            setLocationDeliver={setLocationDeliver}
            deliveryAvailable={deliveryAvailable}
            setDeliveryAvailable={setDeliveryAvailable}
            resturantId={resturantId}
            value={value}
            setValue={setValue}
          />
          {getDefaultService()}
        </div>
      );
  }
};

export default TabsDynamicConditionalComponent;
