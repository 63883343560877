import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import Dropdown from "react-bootstrap/Dropdown";
import "bootstrap/dist/css/bootstrap.min.css";

import { Tab, Tabs, Box } from "@mui/material";

import { isEmpty } from "lodash";

import "./style.css";
import { useTranslation } from "react-i18next";
import { retrunLanguage } from "../../../../utils/languageSelect";

const menu_categories = [
  "Burrito in a Bowl",
  "Quesadilla",
  "Burrito",
  "Sides",
  "Carne",
  "Poultry",
  "Pescheria",
  "Pasta",
  "Pizza",
  "Dessert",
  "Soft drinks",
];

const MenuItems = ({ itemDetail, itemCategoryList }) => {
  const { i18n } = useTranslation();

  const [value, setValue] = React.useState(0);
  const [categories, setCategories] = React.useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const restaurantTheme = useSelector((state) => state.theme.themeColor);
  const visibleCategoryElementID = useSelector(
    (state) => state.resturants.visibleCategoryElementID
  );
  const scrollToElement = (id, index) => {
    if (index || index === 0) {
      setValue(index);
    }

    const container = document.getElementById(id);
    if (container) {
      container.scrollIntoView({ behavior: "auto" });
    }
  };

  useEffect(() => {
    setValue(parseInt(visibleCategoryElementID - 1));
  }, [visibleCategoryElementID]);

  useEffect(() => {
    if (itemDetail && itemDetail?.length) {
      const ifItemsExists = itemCategoryList?.filter((category) => {
        const itemIndex = itemDetail.findIndex(
          (item) => item.category_id === category.id
        );

        if (itemIndex !== -1) {
          return { ...category };
        }
      });

      setCategories(ifItemsExists);
    } else {
      setCategories(itemCategoryList);
    }
  }, [itemDetail, itemCategoryList]);

  return (
    <div
      className={`menu_item_outer_container px-3 px-sm-4 ${
        i18n.language === "ar" ? "container-ar" : "container-en"
      }`}
    >
      <div className="menu_item_inner_container">
        <div className="menu_dropdown_container">
          <Dropdown>
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              style={{
                backgroundColor: "transparent",
                color: "#323F4B",
                fontFamily: "Titillium Web",
                fontSize: "10pt",
                fontWeight: "bold",
                border: "none",
                padding: "0",
                width: "100%",
              }}
            >
              <div className="dropdown_icon_container_menu">
                <img src="/assets/img/menu_Icon.svg" alt="chevron_down_icon" />
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu
              style={{
                backgroundColor: "#F0F6F5",
                color: "#323F4B",
                fontFamily: "Titillium Web",
                fontSize: "14px",
                fontWeight: "bold",
                border: "none",
                maxHeight: "150px",
                overflowY: "scroll",
                scrollbarWidth: "none",
                borderRadius: "0 0 16px 16px",
                position: "static",
              }}
            >
              {!isEmpty(categories)
                ? categories?.map((category, index) => (
                    <Dropdown.Item
                      key={category.id}
                      onClick={() => {
                        scrollToElement(index + 1, index);
                      }}
                      className={retrunLanguage(i18n) && "name-ar"}
                    >
                      {retrunLanguage(i18n)
                        ? category?.name_ar
                        : category?.name_en}
                    </Dropdown.Item>
                  ))
                : menu_categories.map((category, index) => (
                    <Dropdown.Item
                      key={`menu_category_drop_${index}`}
                      onClick={() => {
                        scrollToElement(index + 1, index);
                      }}
                    >
                      {category}
                    </Dropdown.Item>
                  ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="menu_itmes_text_container">
          <Box
            sx={{
              maxWidth: { xs: "100%" },
              bgcolor: "background.paper",
            }}
          >
            <Tabs
              value={value === -1 ? value + 1 : value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons={true}
              textColor="black"
              aria-label="scrollable prevent tabs example"
              TabIndicatorProps={{
                style: { backgroundColor: restaurantTheme?.main },
              }}
              sx={{
                "& .MuiButtonBase-root": {
                  textTransform: "none",
                  minWidth: "0px",
                  marginRight: "12px",
                },
              }}
            >
              {categories?.map((val, index) => {
                return (
                  <Tab
                    className={retrunLanguage(i18n) ? "tab-ar" : "tab-en"}
                    onClick={() => scrollToElement(index + 1)}
                    key={val.id}
                    label={
                      retrunLanguage(i18n)
                        ? val?.name_ar.charAt(0).toUpperCase() +
                          val?.name_ar.slice(1).toLowerCase()
                        : val?.name_en.charAt(0).toUpperCase() +
                          val?.name_en.slice(1).toLowerCase()
                    }
                  />
                );
              })}
            </Tabs>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default MenuItems;
