import React from "react";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { retrunLanguage } from "../../utils/languageSelect";

import { paths } from "../../routes/paths";

import "./style.css";

const Card = (props) => {
  const { t, i18n } = useTranslation("address");

  const {
    iconUrl,
    data,
    open,
    selectCurrentAddress,
    deselectCurrentAddress,
    isSelected,
    handleClickOpen,
    home,
    chooseAddress,
    location,
  } = props;

  const navigate = useNavigate();
  const latlng = { lat: data.latitude, lng: data.longitude };

  return (
    <div
      className={[
        "addresses-container",
        !home && isSelected ? "dark-bg" : home ? "no-cursor" : "",
      ].join(" ")}
    >
      <div className="addresses-header">
        <div className="addresses-title">
          <div
            className="addresses-card-div"
            style={{
              margin: retrunLanguage(i18n)
                ? "0px 0px 0px 3.8px"
                : "0px 3.8px 0px 0px",
            }}
          >
            <img className="addresses-card-icon" src={iconUrl} alt="Icon" />
          </div>

          <div className="addresses-titles">
            <p className="addresses-home-title">{data?.address_type}</p>
            <p className="addresses-home-title2">{data?.address_type}</p>
            {/* data.address */}
          </div>
        </div>
        <div className={["addresses-button-container", open ? "zIndex" : ""]}>
          {!home ? (
            <button
              className="addresses-delete-button"
              style={{
                margin: retrunLanguage(i18n)
                  ? "0px 0px 0px 10px"
                  : "0px 10px 0px 0px",
              }}
              onClick={() => {
                !home && handleClickOpen(data);
              }}
            >
              <span className="addresses-delete-text">
                {t("delete", { ns: "common" })}
              </span>
            </button>
          ) : null}

          <button
            className="addresses-edit-button"
            onClick={() => {
              const initialState = {
                data: data,
                latlng,
                edit: true,
              };

              home
                ? chooseAddress()
                : location?.state?.fromCart !== undefined
                ? navigate(paths.address, {
                    state: {
                      ...initialState,
                      path: location?.state?.path,
                      fromCart: location?.state?.fromCart,
                      fromHome: location?.state?.fromHome,
                    },
                  })
                : location?.state?.fromHome !== undefined
                ? navigate(paths.address, {
                    state: {
                      ...initialState,
                      path: "/home",
                      fromHome: location?.state?.fromHome,
                    },
                  })
                : location?.state?.fromMenu !== undefined
                ? navigate(paths.address, {
                    state: {
                      ...initialState,
                      path: "menu/restaurant",
                      fromMenu: location?.state?.fromMenu,
                      restaurant_id: location?.state?.restaurant_id,
                    },
                  })
                : location?.state?.fromDetail !== undefined
                ? navigate(paths.address, {
                    state: {
                      ...initialState,
                      path: "menu/restaurant",
                      fromDetail: location?.state?.fromDetail,
                      restaurant_id: location?.state?.restaurant_id,
                    },
                  })
                : navigate(paths.address, {
                    state: {
                      ...initialState,
                      path: location?.state?.path,
                    },
                  });
            }}
          >
            <span className="addresses-edit-text">
              {!home
                ? t("edit", { ns: "common" })
                : t("choose", { ns: "common" })}
            </span>
          </button>
        </div>
      </div>
      <hr />

      <div
        className="addresses-content"
        onClick={() => {
          !home && !isSelected
            ? selectCurrentAddress(data)
            : !home && deselectCurrentAddress();
        }}
      >
        <div className="addresses-details-and-map">
          <div className="addresses-details">
            <p className="addresses-name">
              {t("block")}: {data?.block}, {t("street")}:{data?.street}
            </p>

            {data?.address_type?.toLowerCase() === "house" ? (
              <p className="addresses-name">
                {t("houseNo")}: {data?.house_no}
              </p>
            ) : (
              <p className="addresses-name">
                {t("building")}: {data?.building}
              </p>
            )}

            <p className="addresses-name">{data?.avenue}</p>

            {data?.address_type?.toLowerCase() === "apartment" ? (
              <p className="addresses-name">{`${t("floor")}: ${
                data?.floor
              }, ${t("apartment")}: ${data?.apartment_no}`}</p>
            ) : data?.address_type?.toLowerCase() === "office" ? (
              <p className="addresses-name">{`${t("floor")}: ${data?.floor}, ${
                data?.company_name
              }`}</p>
            ) : null}

            <p className="addresses-name">{data?.address_label}</p>

            {data?.additional_direction !== "" ? (
              <p className="additional-addresses">
                {t("additionalDir")}: <br /> {data?.additional_direction}
              </p>
            ) : null}
          </div>
        </div>
        <div className="addresses-map-section">
          <img src="/assets/img/map-image.svg" alt="Small Image" />
        </div>
      </div>
    </div>
  );
};

export default Card;
