import axiosInstance from "../axiosInstance";
import API_ENDPOINTS from "../endpoints";
import { handleApiError } from "../utils/errorHandling";

const getMenuItemDetailsDirect = async (menuItemId) => {
  try {
    // Make the GET request to fetch menu item details.
    const { data } = await axiosInstance.get(
      API_ENDPOINTS.MENU.GET_MENU_ITEM_DETAILS_DIRECT_LINK + `${menuItemId}`
    );
    return data;
  } catch (error) {
    // Use the handleApiError function for error handling
    handleApiError(error);
  }
};

export default getMenuItemDetailsDirect;
