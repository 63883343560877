import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";

import { retrunLanguage } from "../../../utils/languageSelect";

const ExpandedButton = (props) => {
  const {
    iconSrc,
    text,
    hideOther,
    otherText,
    buttonOneText,
    buttonTwoText,
    clicked,
    handleClick,
    id,
    handleOrderTypeFilter,
    filterClassDelivery,
    filterClassNear,
    filterSelectedNow,
    isButtonExpanded,
    selectedId,
    customButtonExpandedClass,
    handleFilterClick,
    notIncludes,
    selectedText,
  } = props;

  const { i18n, t } = useTranslation("landingPage");

  useEffect(() => {
    handleOrderTypeFilter(
      filterClassDelivery,
      filterClassNear,
      filterSelectedNow
    );
  }, [filterClassDelivery, filterClassNear]);

  return (
    <div
      className={`custom-button ${
        isButtonExpanded && selectedId === id
          ? `expanded ${retrunLanguage(i18n) ? "add-padding" : ""}`
          : ""
      } ${
        clicked ? "clicked" : ""
      } ${customButtonExpandedClass} ${notIncludes}`}
    >
      <img
        src={iconSrc}
        alt="Icon"
        className="orderTypebutton-icon"
        onClick={() => handleClick(text === "" ? otherText : text, id)}
      />
      {(hideOther || !hideOther) && (
        <span
          onClick={() =>
            handleClick(
              hideOther && selectedText !== t("catering") ? otherText : text,
              id
            )
          }
          className={`orderTypebutton-text ${
            retrunLanguage(i18n) ? "type-ar" : "type-en"
          } ${!hideOther && !text ? "text-width" : ""}`}
        >
          {hideOther && selectedText !== t("catering") ? otherText : text}
        </span>
      )}
      <span className="orderTypebutton-content">
        {isButtonExpanded && selectedId === id && (
          <div className="options">
            <button
              className={`orderTypebutton-style-one ${
                filterClassDelivery && "orderTypebutton-style-one-active"
              }`}
              onClick={() => handleFilterClick(buttonOneText)}
            >
              {buttonOneText}
            </button>
            <button
              className={`orderTypebutton-style-two ${
                filterClassNear && "orderTypebutton-style-two-active"
              }`}
              onClick={() => handleFilterClick(buttonTwoText)}
            >
              {buttonTwoText}
            </button>
          </div>
        )}
      </span>
    </div>
  );
};

export default ExpandedButton;
