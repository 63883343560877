import React, { useEffect, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";

import { useTranslation } from "react-i18next";

import { setKey, geocode, RequestType, setLanguage } from "react-geocode";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import RouteProvider from "./routes/routes";

import countryList from "./api/common/getAllCountries";
import getAllArea from "./api/address/areas";
import getAllRestaurants from "./api/home/getAllRestaurants";

import { addCountry, allCountries } from "./store/reducers/authSlice";
import { getResturants } from "./store/reducers/resturantsSlice";
import { getAreas } from "./store/reducers/addressSlice";

import { saveMatchedAreaName } from "./utils/checkOperationalArea";
import { flattenArray } from "./utils/getAddress";

import "./App.css";

const LazyChatScript = React.lazy(() =>
  import("./components/chat/LazyChatScript")
);

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    // Create a function to detect the browser language
    const detectBrowserLanguage = () => {
      const nav = window.navigator;
      const browserLanguagePropertyKeys = [
        "language",
        "browserLanguage",
        "systemLanguage",
        "userLanguage",
      ];

      // Prioritize keys based on browser support
      for (let key of browserLanguagePropertyKeys) {
        if (nav[key]) {
          return nav[key];
        }
      }

      // If no property is available, return default
      return "en-US"; // Return default language if none found
    };

    i18n.changeLanguage(detectBrowserLanguage());
  }, []);

  useEffect(() => {
    document.body.dir = i18n.dir();

    if (i18n.language === "ar") {
      document.body.className = "arabic-version";
    } else {
      document.body.className = "";
    }
  }, [i18n, i18n.language]);

  // Usage

  const currentURL = useLocation();

  const restaurantId = localStorage.getItem("restaurantID");

  const allAreas = useSelector((state) => state.address.allAreas);
  const countryCode = useSelector(
    (state) => state.auth.user.mobile_country_code
  );
  const restaurantData = useSelector(
    (state) => state.resturants.resturantsData
  );

  const dispatch = useDispatch();

  const fetchAllCountries = () => {
    countryList()
      .then((res) => {
        const countryByCode = res.data.find((val) => {
          if (countryCode) {
            return val.country_code == countryCode;
          }
          return val.is_default == "Yes";
        });
        localStorage.setItem("country", JSON.stringify(countryByCode));
        dispatch(addCountry(countryByCode));
        dispatch(allCountries(res.data));

        if (location?.pathname !== "/location") {
          fetchAllAreas();
        }

        if (
          location.pathname !== "/" &&
          location.pathname.includes("restaurant")
        ) {
          fetchRestaurants(countryByCode);
        }
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchAllAreas = () => {
    getAllArea()
      .then((res) => {
        const flattenedArray = flattenArray(res.data);

        dispatch(getAreas(flattenedArray));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchRestaurants = (countryByCode) => {
    getAllRestaurants(countryByCode?.id)
      .then((res) => {
        dispatch(getResturants(res?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchAllCountries();
  }, []);

  const setPageTitle = () => {
    let title = "Delivering Happiness to your Doorstep";

    if (
      currentURL.pathname.includes("restaurant") &&
      restaurantData?.length !== 0 &&
      restaurantId !== null
    ) {
      const restaurantName = restaurantData?.find(
        (val) => val.id == +restaurantId
      )?.name_en;
      if (restaurantName === "Cafad") {
        title = "Cafeteria Al Dhahiya Menu";
      } else {
        title = restaurantName + " " + "Menu";
      }
    } else {
      title = "Delivering Happiness to your Doorstep";
    }
    document.title = title;
  };

  useEffect(() => {
    setPageTitle();
  }, [currentURL, restaurantData, restaurantId]);

  useEffect(() => {
    // Additional logic for page-specific CSS
    if (
      currentURL.pathname === "/menu/restaurant/1/delivery" ||
      currentURL.pathname === "/menu-item-details"
    ) {
      // Apply additional styles for the specific page
      // For example, you can add a class to the body or any specific element
      document.body.classList.add("specific-page-body-style");
    } else {
      // Remove the class for other pages
      document.body.classList.remove("chat-icon");
    }
  }, [currentURL]);

  useEffect(() => {
    navigator.permissions &&
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (PermissionStatus) {
          localStorage.setItem("locatPermission", PermissionStatus.state);
        });
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    setKey(process.env.REACT_APP_GOOGLE_API_KEY);
    setLanguage("en");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          geocode(
            RequestType.LATLNG,
            `${position.coords.latitude},${position.coords.longitude}`,
            {
              enable_address_descriptor: true,
            }
          )
            .then(({ results }) => {
              const address = results[0].formatted_address;
              const lat = results[0].geometry.location.lat;
              const lng = results[0].geometry.location.lng;

              if (localStorage.getItem("location") === null) {
                saveCurrentLocation(lat, lng, address);
              }

              if (localStorage.getItem("matchingArea") === null) {
                if (sessionStorage.getItem("matchingArea") === null) {
                  callFunctionForMatchArea(results[0].address_components);
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        },
        () => {}
      );
    }
  }, [allAreas]);

  const callFunctionForMatchArea = (addressComponent) => {
    const localityComponents = addressComponent.filter(
      (component) =>
        component.types.includes("locality") ||
        component.types.includes("sublocality_level_1") ||
        component.types.includes("sublocality") ||
        component.types.includes("natural_feature") ||
        component.types.includes("route") ||
        component.types.includes("neighborhood")
    );

    const addressHasAdminAreaLevel1 = addressComponent.filter(
      (component) =>
        component.types.includes("administrative_area_level_1") &&
        !component.types.includes("locality")
    );

    if (allAreas.length > 0) {
      if (localityComponents.length > 0) {
        saveMatchedAreaName(localityComponents, allAreas);
      } else if (addressHasAdminAreaLevel1.length > 0) {
        saveMatchedAreaName(addressHasAdminAreaLevel1, allAreas);
      }
    }

    sessionStorage.setItem("matchingArea", true);
  };

  const saveCurrentLocation = (lat, lng, address) => {
    const addToStorage = {
      lat: lat,
      lng: lng,
      address: address,
    };

    localStorage.setItem("location", JSON.stringify(addToStorage));
  };

  return (
    <>
      {currentURL.pathname === "/countdown" && <RouteProvider />}
      {currentURL.pathname !== "/countdown" && (
        <div className="container_main">
          <div className="design-section">
            {/* Design section content */}
            <RouteProvider />
            <Suspense fallback={<div>Loading...</div>}>
              <LazyChatScript />
            </Suspense>
          </div>
          <div className="content-section">
            <ToastContainer
              position="top-left"
              autoClose={2500}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
          </div>
        </div>
      )}
    </>
  );
}

export default App;
