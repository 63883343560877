export const privacyContent = {
  general_term: [
    {
      id: 1,
      heading: "Introduction",
      detail:
        "Welcome to OrderSeazen. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you use our website and services. By accessing or using OrderSeazen, you agree to the collection and use of information in accordance with this policy.",
    },
    {
      id: 2,
      heading: "Information Collection",
      sub_data: [
        {
          id: 2.1,
          sub_heading: "Personal Information",
          sub_detail:
            "We collect information that personally identifies you, such as your name, email address, phone number, and address when you register or place an order.",
        },
        {
          id: 2.2,
          sub_heading: "Usage Data",
          sub_detail:
            "We collect information on how the Service is accessed and used, including details like your IP address, browser type, and pages visited.",
        },
      ],
    },
    {
      id: 3,
      heading: "Use of Information",
      sub_data: [
        {
          id: 3.1,
          sub_heading: "To Provide Service",
          sub_detail:
            "Your information is used to fulfill your orders, manage your account, delivering your orders, payment platform and payment service provider, telecommunication service provider, and provide customer support.",
        },
        {
          id: 3.2,
          sub_heading: "Communication",
          sub_detail:
            "We may use your information to contact you with newsletters, marketing materials, or promotional offers unless you opt-out.",
        },
        {
          id: 3.3,
          sub_heading: "Improvement of Service",
          sub_detail:
            "Information is used for analytics and service improvement purposes.",
        },
        {
          id: 3.4,
          sub_heading: "Marketing",
          sub_detail:
            "Analyse and research how to improve our services both offline and on the Platform, by using the data provided by you as well as carry out marketing, communications, research and development activities.",
        },
      ],
    },
    {
      id: 4,
      heading: "Data Sharing and Disclosure",
      sub_data: [
        {
          id: 4.1,
          sub_heading: "Third-Party Service Providers",
          sub_detail:
            "We may employ third-party companies to facilitate our Service, such as payment processing, delivery services, or analytics providers.",
        },
        {
          id: 4.2,
          sub_heading: "Legal Requirements",
          sub_detail:
            "We may disclose your information if required by law or in response to valid requests by public authorities.",
        },
      ],
    },
    {
      id: 5,
      heading: "Data Security",
      sub_data: [
        {
          id: 5.1,
          sub_heading: "Security Measures",
          sub_detail:
            "We take data security seriously and implement appropriate measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.",
        },
        {
          id: 5.2,
          sub_heading: "Data Breach Procedures",
          sub_detail:
            "In case of a data breach, we will take prompt action in accordance with legal requirements and inform affected individuals where required under the relevant applicable laws.",
        },
      ],
    },
    {
      id: 6,
      heading: "Data Retention",
      sub_data: [
        {
          id: 6.1,
          sub_heading: "Retention Period",
          sub_detail:
            "We retain your personal information only for as long as necessary to provide you with our services and as required by law.",
        },
        {
          id: 6.2,
          sub_heading: "Deletion of Data",
          sub_detail:
            "Upon request, we will delete your personal data, subject to any legal obligations to retain such information. Deletion of your data may entail suspension of your access to the Services.",
        },
      ],
    },
    {
      id: 7,
      heading: "International Data Transfers",
      sub_data: [
        {
          id: 7.1,
          sub_heading: "Cross-Border Transfer",
          sub_detail:
            "Where allowed for under applicable law, information we collect may be transferred and processed in Kuwait, where our headquarters are located. By using our service, you consent to this transfer.",
        },
        {
          id: 7.2,
          sub_heading: "Data Protection Measures",
          sub_detail:
            "We take all steps reasonably necessary to ensure your data is treated securely and in accordance with this Privacy Policy.",
        },
      ],
    },
    {
      id: 8,
      heading: "Your Data Protection Rights",
      sub_data: [
        {
          id: 8.1,
          sub_heading: "Access and Control",
          sub_detail:
            "You have the right to access, update, or delete your account. You can do this by contacting us directly or from your profile page.",
        },
        {
          id: 8.2,
          sub_heading: "Consent Withdrawal",
          sub_detail:
            "You have the right to withdraw your consent at any time where we rely on your consent to process your personal information.",
        },
      ],
    },
    {
      id: 9,
      heading: "Children's Privacy",
      sub_data: [
        {
          id: 9.1,
          sub_heading: "Protection of Children",
          sub_detail:
            "Our Service does not address anyone under the age of 18. We do not knowingly collect personally identifiable information from children.",
        },
      ],
    },
    {
      id: 10,
      heading: "Changes to This Privacy Policy",
      sub_data: [
        {
          id: 10.1,
          sub_heading: "Policy Updates",
          sub_detail:
            "We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new policy on our website.",
        },
      ],
    },
    {
      id: 11,
      heading: "Governing Law and Jurisdiction",
      sub_data: [
        {
          id: 11.1,
          sub_heading: "Applicable Law",
          sub_detail:
            "This Privacy Policy is governed by the laws of of the country from which the User is using the Services . Disputes arising in relation to this policy will be subject to the jurisdiction of of the country from which the User is using the Services .",
        },
      ],
    },
  ],
};
