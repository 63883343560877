export const filterPickupRestaurants = (
  restaurantBranchData,
  restaurantsData
) => {
  return restaurantBranchData?.filter((branch) => {
    return restaurantsData?.find(
      (restaurant) =>
        restaurant.id === branch.restaurant_id &&
        restaurant.pickup_status === true
    );
  });
};
