import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import { retrunLanguage } from "../../../utils/languageSelect";

import { paths } from "../../../routes/paths";

import "./style.css";

const Navbar = (props) => {
  const { i18n } = useTranslation();

  const {
    restaurantDetail,
    setOpenAddressPopup,
    fromMenu,
    setPickupFromDialog,
  } = props;

  const navigate = useNavigate();

  const cart = useSelector((state) => state.cart.cart);
  const addressData = useSelector((state) => state.address.addressData);
  const totalQuantity = useSelector((state) => state.cart.totalQuantity);
  const typeStatus = useSelector((state) => state.resturants.typeStatus);
  const pickupBranchName = useSelector(
    (state) => state.resturants.pickupBranchName
  );
  const allBranches = useSelector((state) => state.resturants.allBranches);

  const token = localStorage.getItem("token");
  const currentLocation = JSON.parse(localStorage.getItem("location"));
  const isAfterNewLocation = JSON.parse(
    sessionStorage?.getItem("storeAddress")
  );

  const defaultImagePath = "/assets/img/menu_background_image.png";
  const melenzaneImagePath = "/assets/img/melenzane.png";
  const trapaniImagePath = "/assets/img/trapani.jpeg";

  useEffect(() => {
    const contentDiv = document.querySelector(".content-section");
    let oldBackground = window.getComputedStyle(contentDiv).backgroundImage;

    if (contentDiv && restaurantDetail?.main_big_image) {
      contentDiv.style.backgroundImage = `url('${restaurantDetail?.main_big_image}'), ${oldBackground}`;
    } else {
      contentDiv.style.backgroundImage = "";
    }

    return () => {
      contentDiv.style.backgroundImage = "";
    };
  }, [restaurantDetail]);

  useEffect(() => {
    const contentDiv = document.querySelector(".content-section");
    let oldBackground = window.getComputedStyle(contentDiv).backgroundImage;

    if (contentDiv && restaurantDetail?.main_big_image) {
      contentDiv.style.backgroundImage = `url('${restaurantDetail?.main_big_image}'), ${oldBackground}`;
    } else {
      contentDiv.style.backgroundImage = "";
    }

    return () => {
      contentDiv.style.backgroundImage = "";
    };
  }, [restaurantDetail]);

  const addPreserveToStore = () => {
    localStorage.setItem(
      "preserevedBranchId",
      +localStorage?.getItem("branchId")
    );
    localStorage.setItem(
      "preserevedRestaurantID",
      +localStorage?.getItem("restaurantID")
    );
  };

  const returnBranchName = (brName) => {
    const branch = allBranches
      ?.filter((branch) => branch.restaurant_id === cart[0]?.restaurant_id)
      ?.some((branch) =>
        retrunLanguage(i18n)
          ? branch.name_ar === brName
          : branch.name_en === brName
      );

    return branch;
  };

  return (
    <div
      className="Nav_Image_Container"
      style={{
        backgroundImage: `url(${
          restaurantDetail?.name_en === "Melenzane"
            ? melenzaneImagePath
            : restaurantDetail?.cover_page_image || defaultImagePath
        })`,
      }}
    >
      {/* {restaurantDetail?.cover_page_image ? (
        <img alt="background_image" src={restaurantDetail?.cover_page_image} />
      ) : (
        <img
          alt="background_image"
          src="\assets\img\menu_background_image.png"
        />
      )} */}
      <nav className="upper_navigation">
        <div
          className={`back_button ${
            retrunLanguage(i18n) ? "arabic_back" : "english_back"
          }`}
          onClick={() => navigate(paths.home)}
        >
          <div className="back_button-icon">
            <img alt="Arrow_Icon" src="/assets/img/Icon - Arrow.svg" />
          </div>
        </div>
        <div
          className={`shopping_container pointer ${
            retrunLanguage(i18n) ? "arabic_shop" : "english_shop"
          }`}
          onClick={() => {
            token === null
              ? navigate(paths.cart)
              : isAfterNewLocation !== null &&
                isAfterNewLocation?.addNewAddress === true &&
                localStorage.getItem("selected") === null &&
                cart.length > 0 &&
                typeStatus &&
                typeStatus === "delivery"
              ? navigate(paths.address, {
                  state: {
                    latlng: {
                      lat: currentLocation?.lat,
                      lng: currentLocation?.lng,
                    },
                    name: currentLocation?.address,
                    path: "menu/restaurant",
                    restaurant_id: cart[0]?.restaurant_id,
                  },
                })
              : addressData?.length === 0 &&
                cart.length &&
                typeStatus &&
                typeStatus === "delivery"
              ? navigate(paths.location, {
                  state: {
                    noAddress: true,
                    latlng: {
                      lat: currentLocation?.lat,
                      lng: currentLocation?.lng,
                    },
                    name: currentLocation?.address,
                    path: "menu/restaurant",
                    restaurant_id: cart[0]?.restaurant_id,
                  },
                })
              : addressData?.length > 0 &&
                localStorage.getItem("selected") === null &&
                cart.length > 0 &&
                typeStatus &&
                typeStatus === "delivery"
              ? setOpenAddressPopup(true)
              : cart?.length > 0 &&
                typeStatus &&
                typeStatus === "pickup" &&
                (localStorage.getItem("branchName") === null ||
                  returnBranchName(localStorage.getItem("branchName")) ===
                    false)
              ? setPickupFromDialog(true)
              : navigate(paths.cart, {
                  state: {
                    fromMenu,
                    path: "menu/restaurant",
                    restaurant_id: cart[0]?.restaurant_id,
                  },
                });

            addPreserveToStore();
          }}
        >
          <div className="shopping_icon">
            <img alt="Bag_Icon" src="/assets/img/Icon - Bag.svg" />
            {totalQuantity > 0 && (
              <div className="items-count">{totalQuantity}</div>
            )}
          </div>
        </div>
      </nav>
      {/* <MenuNavbar backIcon cartIcon /> */}
    </div>
  );
};

export default Navbar;
