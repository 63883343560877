import React from "react";

import "./style.css";

export const AppartmentAdress = (props) => {
  const {
    handleChange,
    address,
    blockErr,
    streetErr,
    buildingErr,
    floorErr,
    apartmentNoerr,
    i18n,
    t,
  } = props;

  return (
    <form className={`container-style px-3 ${i18n ? "input-change" : ""}`}>
      <div
        className={`inputwrapper ${blockErr ? "errorinputwrapper" : ""}${
          i18n ? "inputwrapper-ar" : "inputwrapper-en"
        }`}
        data-required={t("required")}
      >
        <input
          className={`form-style ${blockErr ? "error" : ""}`}
          type="text"
          name="Block"
          placeholder={t("block")}
          onChange={(e) => handleChange("block", e.target.value)}
          value={address.block}
        />
      </div>

      <div
        className={`inputwrapper ${streetErr ? "errorinputwrapper" : ""}${
          i18n ? "inputwrapper-ar" : "inputwrapper-en"
        }`}
        data-required={t("required")}
      >
        <input
          className={`form-style ${streetErr ? "error" : ""}`}
          type="text"
          name="Street"
          placeholder={t("street")}
          onChange={(e) => handleChange("street", e.target.value)}
          value={address.street}
        />
      </div>

      <input
        className="form-style"
        type="text"
        name="Avenue"
        placeholder={t("avenue")}
        onChange={(e) => handleChange("avenue", e.target.value)}
        value={address.avenue}
      />

      <div
        className={`inputwrapper ${buildingErr ? "errorinputwrapper" : ""}${
          i18n ? "inputwrapper-ar" : "inputwrapper-en"
        }`}
        data-required={t("required")}
      >
        <input
          className={`form-style ${buildingErr ? "error" : ""}`}
          type="text"
          name="Building"
          placeholder={t("building")}
          onChange={(e) => handleChange("building", e.target.value)}
          value={address.building}
        />
      </div>

      <div
        className={`inputwrapper ${floorErr ? "errorinputwrapper" : ""}${
          i18n ? "inputwrapper-ar" : "inputwrapper-en"
        }`}
        data-required={t("required")}
      >
        <input
          className={`form-style ${floorErr ? "error" : ""}`}
          type="text"
          name="Floor"
          placeholder={t("floor")}
          onChange={(e) => handleChange("floor", e.target.value)}
          value={address.floor}
        />
      </div>

      <div
        className={`inputwrapper ${apartmentNoerr ? "errorinputwrapper" : ""}${
          i18n ? "inputwrapper-ar" : "inputwrapper-en"
        }`}
        data-required={t("required")}
      >
        <input
          className={`form-style ${apartmentNoerr ? "error" : ""}`}
          type="text"
          name="Appartment No"
          placeholder={t("apartNumber")}
          onChange={(e) => handleChange("apartment_no", e.target.value)}
          value={address.apartment_no}
        />
      </div>

      <input
        className="form-style"
        type="text"
        name="Address Label"
        placeholder={t("addressLabel")}
        onChange={(e) => handleChange("address_label", e.target.value)}
        value={address.address_label}
      />

      <textarea
        className="form-style form-style-text-area"
        name="AdditionalDirection"
        id="AdditionalDirection"
        placeholder={t("additionalDir")}
        onChange={(e) => handleChange("additional_direction", e.target.value)}
        value={address.additional_direction}
        rows="4"
      ></textarea>
    </form>
  );
};
