import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

// import { useQuery } from "@tanstack/react-query";

// import { v4 as uuidv4 } from "uuid";

import Dropdown from "react-bootstrap/Dropdown";
import "bootstrap/dist/css/bootstrap.min.css";

import {
  Stack,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";

import { styled } from "@mui/material/styles"; // Import styled from MUI
import { lighten } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

import ScheduleDialog from "../../../../components/dialog/scheduleDialog";

import { DialogComponent } from "../../../../components/dialog/index.js";
import ScheduleOrder from "../../scheduleOrder/index.js";
import RemoveFromCart from "../../../dialog/differentBranchRestaurant.js";
import getListItemByCategory from "../../../../api/restaurant/getListItemsByCategory";
import getItemCategoryList from "../../../../api/restaurant/getItemCategoryList";
import getBranchByAreaId from "../../../../api/restaurant/branches/getBranchIdBasedOnAreaId";
import menuItemsAvailability from "../../../../api/menu/menuItemsAvailability.js";
import deleteCart from "../../../../api/cart/deleteCartItem.js";
import getAllAddress from "../../../../api/address/getAddress.js";

import {
  getDistanceFromLatLonInKm,
  getFullAddress,
} from "../../../../utils/getAddress";
import { retrunLanguage } from "../../../../utils/languageSelect.js";

import {
  getCategoryItemList,
  getCategoryList,
  toggleCategoryListLoading,
} from "../../../../store/reducers/resturantsSlice";
import { addSelectedAddress } from "../../../../store/reducers/userLocationSlice";
import { removeFromCart } from "../../../../store/reducers/cartSlice.js";
import { getAddress } from "../../../../store/reducers/addressSlice.js";

import { paths } from "../../../../routes/paths";

import "./style.css";

const StyledOuterContainer = styled("div")(({ theme }) => ({
  backgroundColor: lighten(theme.palette.themeColor.light, 0.9), // Apply the background color
  // Add any other styles you need for the outer container here
}));

const StyledOuterContainerIcon = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.themeColor.main, // Apply the background color
  // Add any other styles you need for the outer container here
}));

const DeliveryDetails = (props) => {
  const { i18n, t } = useTranslation("translation");

  const {
    setLocationDeliver,
    restaurantBranchHours,
    onDelivery,
    setOrderAmFromDelivery,
    addressSelected,
    setSelectAddressNotDeliver,
    selectAddressNotDeliver,
    // setNotDeliver
  } = props;

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);

  const addressData = useSelector((state) => state.address.addressData);
  const allAreas = useSelector((state) => state.address.allAreas);
  const resturantsData = useSelector(
    (state) => state.resturants.resturantsData
  );
  const restaurantDetail = useSelector(
    (state) => state.resturants.restaurantDetail
  );
  const branchHours = useSelector((state) => state.resturants.branchHours);
  const country = useSelector((state) => state.auth.country);
  const cart = useSelector((state) => state.cart.cart);

  const selected = JSON.parse(localStorage.getItem("selected"));
  const currentLocation = JSON.parse(localStorage.getItem("location"));
  const token = localStorage.getItem("token");
  const isAsap = localStorage.getItem("isAsap");

  const truncateString = (str, len) => {
    const isArabic = retrunLanguage(i18n); // Assuming retrunLanguage(i18n) returns true for RTL, false otherwise
    if (str) {
      if (str.length <= len) {
        return str;
      } else if (isArabic) {
        // RTL string
        if (len === 30) {
          // If len is 30, add the ellipsis before the closing parenthesis
          return "(..." + str.slice(str.length - len, str.length);
        } else {
          // Otherwise, just add the ellipsis
          return "..." + str.slice(str.length - len, str.length);
        }
      } else {
        // LTR string
        if (len === 30) {
          return str.slice(0, len) + "...)";
        } else {
          return str.slice(0, len) + "...";
        }
      }
    }
  };

  const [latlng, setLatlng] = useState(null);
  const [userLocation, setUserLocation] = useState();
  const restaurantTheme = useSelector((state) => state.theme.themeColor);
  const [openDropdown, setOpendropdown] = useState(false);
  const [fullAddress, setFullAddress] = useState(null);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [restaurantOpen, setRestaurantOpen] = useState(false);
  const [addressID, setAddressID] = useState();
  const [delivery_charges, setDeliveryCharge] = useState();
  const [delivery_time, setDeliveryTime] = useState();
  const [name_en, setNameEn] = useState();
  const [disableMenu, setDisableMenu] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [clearCartModal, setClearCartModal] = useState(false);
  const [removingItem, setRemovingItem] = useState(false);
  const [restName, setRestName] = useState("");
  const [changeAddressDetails, setChangeAddressDetails] = useState(null);
  const [itemsAfterRemove, setItemsAfterRemove] = useState([]);
  const [dispatchCount, setDispatchCount] = useState(0);

  // When user visits menu and restaurant is close
  const [isCheckingAuto, setIsCheckingAuto] = useState(false);

  const { id: restaurant_id } = useParams();

  const fetchFunctionAddresses = async () => {
    getAllAddress(token).then((res) => {
      if (res.success === "ok") {
        const filterAddress = res.data.filter(
          (address) => !Array.isArray(address.delivery_charge_area_info)
        );

        dispatch(getAddress(filterAddress));
      }
    });
  };

  useEffect(() => {
    if (token && !addressData.length) {
      fetchFunctionAddresses();
    }
  }, []);

  const addRestauantName = (id) => {
    const restName = resturantsData?.find((rest) => rest.id === id);

    return restName?.name_en;
  };

  const locationLabelName = () => {
    let text = "";
    const truncLoc = truncateString(currentLocation?.address, 15);

    if (token !== null) {
      if (currentLocation !== null) {
        text = truncLoc;
      }
      if (selected !== null) {
        text = truncateString(selected?.address, 15);
      }
      if (selected === null && currentLocation === null) {
        if (addressData.length) {
          text = t("menu.selectAddress");
        } else {
          text = t("menu.addAddress");
        }
      }
    } else {
      if (currentLocation !== null) {
        text = truncLoc;
      } else {
        text = t("menu.addAddress");
      }
    }
    return text;
  };

  useEffect(() => {
    if (onDelivery) {
      if (sessionStorage.getItem("popupOpened") === null) {
        if (isAsap === "true") {
          const filterRestaurant = branchHours?.filter(
            (branch) => branch.restaurant_id === restaurantDetail.id
          );

          for (let i = 0; i < filterRestaurant?.length; i++) {
            const date = new Date();
            const options = { weekday: "long" };
            const dayName = date.toLocaleDateString("en-US", options);

            const findStatus = filterRestaurant[i]?.branch_hours?.filter(
              (branch) => branch.week_day === dayName
            );

            if (findStatus[0]?.openingStatus === "Open") {
              setRestaurantOpen(false);
              setIsCheckingAuto(false);
              break;

              //
            } else if (findStatus[0]?.openingStatus === "Close") {
              setRestaurantOpen(true);
              setIsCheckingAuto(true);
              //
            }
          }
        } else {
          sessionStorage.setItem("popupOpened", true);
          setRestaurantOpen(false);
        }
      } else {
        sessionStorage.setItem("popupOpened", true);
        setRestaurantOpen(false);
      }
    }
  }, [branchHours, restaurantDetail]);

  useEffect(() => {
    setUserLocation(locationLabelName());
  }, [addressSelected]);

  useEffect(() => {
    let pushedWithDistance = [];
    let num = 0;

    if (restaurantBranchHours?.data?.length) {
      for (let i = 0; i < restaurantBranchHours?.data?.length; i++) {
        const resLat = +restaurantBranchHours?.data[i]?.latitude;
        const resLng = +restaurantBranchHours?.data[i]?.longitude;
        num++;

        let fromLocation;
        if (selected === null && currentLocation !== null) {
          fromLocation = currentLocation;
        } else if (selected !== null && currentLocation !== null) {
          fromLocation = selected;
        }

        if (fromLocation !== null && latlng === null) {
          const distance = getDistanceFromLatLonInKm(
            resLat,
            resLng,
            fromLocation?.lat || 29.3140728,
            fromLocation?.lng || 47.68022
          );

          if (distance > 50) {
            setLocationDeliver(true);
          } else {
            pushedWithDistance.push({
              ...restaurantBranchHours?.data[i],
              distance,
            });

            checkWithBranchIdDeliverZones(pushedWithDistance, fromLocation);
            break;
          }
        }

        if (fromLocation !== null && latlng !== null) {
          const distance = getDistanceFromLatLonInKm(
            resLat,
            resLng,
            fromLocation?.lat || 29.3140728,
            fromLocation?.lng || 47.68022
          );

          if (distance > 50) {
            setLocationDeliver(true);
          } else {
            pushedWithDistance.push({
              ...restaurantBranchHours?.data[i],
              distance,
            });

            checkWithBranchIdDeliverZones(pushedWithDistance, fromLocation);
            break;
          }
        }
      }
    }
  }, [restaurantBranchHours, currentLocation, selected, latlng]);

  const checkWithBranchIdDeliverZones = (
    allDistancesLessThan50,
    fromLocation
  ) => {
    // Find the smallest distance and associated location
    const smallestDistance = Math.min(
      ...allDistancesLessThan50.map((item) => item.distance)
    );
    const smallestDistanceLocation = allDistancesLessThan50.find(
      (item) => item.distance === smallestDistance
    );

    if (smallestDistanceLocation) {
      setLocationDeliver(false);

      if (fromLocation && fromLocation.address) {
        setUserLocation(truncateString(fromLocation?.address, 15));
      } else {
        setUserLocation(locationLabelName());
      }
    }
  };

  useEffect(() => {
    if (
      !isDropdownOpen &&
      token !== null &&
      selected !== null &&
      allAreas.length
    ) {
      const checkInDelivers = allAreas.filter((area) => {
        return (
          area?.name_en === selected?.name_en &&
          area.restaurant_id === +restaurant_id
        );
      });

      if (checkInDelivers.length === 0) {
        setLocationDeliver(true);
      } else {
        setLocationDeliver(false);
      }
    }
  }, [isDropdownOpen, allAreas, selected, restaurant_id]);

  const getUserAddress = (lat, lng) => {
    getFullAddress(lat, lng, setFullAddress);
  };

  const delivTime = () => {
    const deliveryTime =
      localStorage.getItem("area_id") !== null &&
      allAreas.find(
        (area) =>
          area.name_en === selected?.name_en &&
          area.restaurant_id === +restaurant_id
      )?.delivery_time !== undefined
        ? allAreas
            .find(
              (area) =>
                area.name_en === selected?.name_en &&
                area.restaurant_id === +restaurant_id
            )
            ?.delivery_time?.trim()
            ?.split(" ")[0]
        : selected?.delivery_time?.trim()?.split(" ")[0];

    return deliveryTime ? +deliveryTime : 30;
  };

  useEffect(() => {
    if (fullAddress !== null) {
      const addToStore = {
        ...fullAddress,
        addressId: addressID,
        delivery_charges,
        delivery_time,
        // minOrderAmount,
        name_en,
        restaurantName: restName,
      };

      localStorage.setItem("selected", JSON.stringify(addToStore));
      dispatch(addSelectedAddress(addToStore));

      setUserLocation(truncateString(fullAddress?.address, 15));
      setLatlng({
        lat: fullAddress.lat,
        lng: fullAddress.lng,
      });

      setOpendropdown(false);
      setFullAddress(null);
    }
  }, [fullAddress]);

  const toggleDropdown = () => {
    setOpendropdown(!openDropdown);
  };

  const addLocation = () => {
    if (token === null) {
      const restaurant_id = restaurantDetail?.id;

      navigate(paths.location, {
        state: {
          path: "menu/restaurant",
          guestUser: true,
          restaurant_id: restaurant_id,
          restaurantName: restaurantDetail?.page_title_en,
        },
      });
    } else {
      navigate(paths.location, {
        state: {
          path: "menu/restaurant",
          restaurant_id: restaurant_id,
          restaurantName: restaurantDetail?.page_title_en,
          addFromDropdown: true,
        },
      });
    }
  };

  const setCurrentLocation = () => {
    if (token === null) {
      const restaurant_id = restaurantDetail?.id;

      navigate(paths.location, {
        state: {
          path: "menu/restaurant",
          guestUser: true,
          restaurant_id: restaurant_id,
          restaurantName: restaurantDetail?.page_title_en,
          toCurrent: true,
        },
      });
    } else {
      navigate(paths.location, {
        state: {
          path: "menu/restaurant",
          restaurant_id: restaurant_id,
          restaurantName: restaurantDetail?.page_title_en,
          addFromDropdown: true,
          toCurrent: true,
        },
      });
    }
  };

  const commonStateSets = (
    address,
    branch_id,
    delivery_charges,
    delivery_time,
    name_en,
    minimum_order_amount,
    restaurant_id,
    area_id
  ) => {
    setDisableMenu(false);
    setOpendropdown(false);

    getUserAddress(address.latitude, address.longitude);
    setAddressID(address.id);

    fetchAllCategories();
    fetchAllItems(branch_id);

    setDeliveryCharge(delivery_charges?.split(" ")[1]);
    setDeliveryTime(delivery_time);
    // setMinOrderAmount(
    //   address?.delivery_charge_area_info?.minimum_order_amount
    // );

    setNameEn(name_en);
    setOrderAmFromDelivery(minimum_order_amount);
    setRestName(addRestauantName(restaurant_id));

    localStorage.setItem("area_id", area_id);
  };

  const onSelectLocatiom = (address) => {
    setDisableMenu(true);
    setIsDropdownOpen(true);

    if (!Array.isArray(address?.delivery_charge_area_info)) {
      // const object = {
      //   country_id,
      //   delivery_charge_area_id: address?.delivery_charge_area_info.id,
      // };

      // const checkInDelivers = allAreas.filter((area) => {
      //   return (
      //     area?.name_en === address.delivery_charge_area_name_en &&
      //     area.restaurant_id === address.delivery_charge_area_info.restaurant_id
      //   );
      // });

      // if (checkInDelivers?.length === 0) {
      //   setLocationDeliver(true);
      // } else {
      //   setLocationDeliver(false);
      // }
      if (cart?.length > 0) {
        const itemIds = cart?.map((item) => {
          return item.id;
        });

        const availabilityObject = {
          restaurant_id: address.delivery_charge_area_info.restaurant_id,
          branch_id: address.delivery_charge_area_info.branch_id,
          item_ids: itemIds,
        };

        menuItemsAvailability(availabilityObject, token).then((itemRes) => {
          if (itemRes?.data?.unavailable_item_ids?.length > 0) {
            const removeFromCart = cart?.filter((item) =>
              itemRes?.data?.unavailable_item_ids.includes(item.id)
            );

            setItemsAfterRemove(removeFromCart);

            setClearCartModal(true);
            setChangeAddressDetails({
              address: address,
            });
          } else {
            changeAddressAfterSelect(address);
          }
        });
      } else {
        changeAddressAfterSelect(address);
      }

      // getBranchByArea(restaurant_id, object, dispatch)
    } else {
      getUserAddress(address.latitude, address.longitude);
      setAddressID(address.id);

      setDisableMenu(false);
      setOpendropdown(false);
    }
  };

  const fetchAllItems = (branch_id) => {
    getListItemByCategory(
      restaurant_id,
      branch_id,
      searchParams.get("category"),
      country?.id
    )
      .then((res) => {
        dispatch(getCategoryItemList(res?.data));
      })
      .catch((e) => console.error(e));
  };

  const fetchAllCategories = () => {
    dispatch(toggleCategoryListLoading());

    getItemCategoryList(restaurant_id)
      .then((res) => {
        dispatch(getCategoryList(res?.data));
      })
      .catch((e) => console.error(e));
  };

  const handleDocumentClick = useCallback(
    (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setOpendropdown(false);
      }
    },
    [dropdownRef]
  );

  useEffect(() => {
    if (openDropdown) {
      document.addEventListener("click", handleDocumentClick);
    } else {
      document.removeEventListener("click", handleDocumentClick);
    }

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [openDropdown, handleDocumentClick]);

  const changeAddressAfterSelect = (address) => {
    const country_id = JSON.parse(localStorage.getItem("country"))?.id;

    getBranchByAreaId(
      restaurant_id,
      country_id,
      address.delivery_charge_area_name_en,
      dispatch
    )
      .then((res) => {
        if (Array.isArray(res.data)) {
          setLocationDeliver(true);

          // setNotDeliver(true);

          setDisableMenu(false);
          setOpendropdown(false);
          setSelectAddressNotDeliver(true);

          // commonStateSets(
          //   address,
          //   address.delivery_charge_area_info.branch_id,
          //   address.delivery_charge_area_info.delivery_charges,
          //   address.delivery_charge_area_info.delivery_time,
          //   address.delivery_charge_area_name_en,
          //   address.delivery_charge_area_info.minimum_order_amount,
          //   address.delivery_charge_area_info.restaurant_id,
          //   address.delivery_charge_area_info.area_id
          // );

          return;
        }

        // setNotDeliver(false);
        setLocationDeliver(false);
        setSelectAddressNotDeliver(false);

        const matchingArea = {
          area_name_en: res.data?.name_en,
          area_name_ar: res.data.name_ar,
        };

        localStorage.setItem("matchingArea", JSON.stringify(matchingArea));

        commonStateSets(
          address,
          res.data?.branch_id,
          res.data?.delivery_charges,
          res.data?.delivery_time,
          res.data?.name_en,
          res.data?.minimum_order_amount,
          res.data?.restaurant_id,
          res.data?.area_id
        );

        // if (res?.data?.length === 0) {
        //   getBranchIdNearToLocation();
        // } else {
        //   fetchAllCategories();
        //   fetchAllItems(res.data?.branch_id);
        // }

        // fetchNearbyRestaurant(address.latitude, address.longitude);
      })
      .catch((err) => {
        // getUserAddress(address.latitude, address.longitude);
        // setAddressID(address.id);

        setOpendropdown(false);
        setDisableMenu(false);

        console.log(err);
      });
  };

  const clearFunction = () => {
    setRemovingItem(true);

    itemsAfterRemove?.map((item) => {
      deleteCart(
        {
          cart_id: item.cart_id,
          item_id: item.id,
          item_code: item.item_code,
        },
        token
      )
        .then(() => {
          dispatch(removeFromCart(item.id));

          setDispatchCount((prevCount) => prevCount + 1);
        })
        .catch(() => {
          setRemovingItem(false);
        });
    });
  };

  useEffect(() => {
    if (
      dispatchCount === itemsAfterRemove?.length &&
      changeAddressDetails?.address !== undefined
    ) {
      setRemovingItem(false);

      changeAddressAfterSelect(changeAddressDetails?.address);
      setDisableMenu(false);
      setOpendropdown(false);
      setClearCartModal(false);
    }
  }, [dispatchCount, itemsAfterRemove]);

  return (
    <>
      {/* {openDatePicker && <Overlay />} */}
      <StyledOuterContainer
        className="delivery_outer_container"
        style={{
          background: restaurantTheme.main + 24,
          position: "relative",
          width: "100%",
        }}
      >
        <div className="delivery_inner_container">
          <div
            className={`left_dropdown_container ${
              retrunLanguage(i18n) && "dropdownfor-ar"
            }`}
          >
            <div className="left_dropdown_container_heading_container">
              {t("menu.deliverTo")}
            </div>
            <Dropdown
              style={{ width: "100%" }}
              show={openDropdown}
              onToggle={toggleDropdown}
              ref={dropdownRef}
            >
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                style={{
                  backgroundColor: "transparent",
                  color: "#323F4B",
                  fontFamily: "Titillium Web",
                  fontSize: "10px",
                  fontWeight: "bold",
                  border: "none",
                  padding: "0",
                }}
              >
                {userLocation}{" "}
                <StyledOuterContainerIcon
                  className="dropdown_icon_container"
                  style={{
                    marginRight: retrunLanguage(i18n) ? "3px" : "0px",
                  }}
                >
                  <img src="/assets/img/dropdown.svg" alt="chevron_down_icon" />
                </StyledOuterContainerIcon>
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{
                  backgroundColor: "#F0F6F5",
                  color: "#323F4B",
                  fontFamily: "Titillium Web",
                  fontSize: "11pt",
                  fontWeight: "bold",
                  border: "none",
                  transform: "translate(0px, -22px)",
                  maxHeight: "200px",
                  width: "250px",
                  maxWidth: "250px",
                  overflowY: "scroll",
                  scrollbarWidth: "none",
                }}
              >
                <Dropdown.Item
                  defaultChecked
                  onClick={addLocation}
                  disabled={disableMenu}
                  className={retrunLanguage(i18n) && "name-ar"}
                >
                  {t("menu.addLocation")}
                </Dropdown.Item>

                <Dropdown.Item
                  defaultChecked
                  onClick={setCurrentLocation}
                  disabled={disableMenu}
                  className={retrunLanguage(i18n) && "name-ar"}
                >
                  {t("menu.selectCurrent")}
                </Dropdown.Item>

                {addressData?.length ? (
                  addressData?.map((address) => {
                    const addressLabel =
                      address.address_label !== ""
                        ? address.address_label
                        : address.address_type;

                    const house = `${addressLabel} (${
                      address?.building !== ""
                        ? `${t("menu.build")}: ${address?.building}, `
                        : ""
                    }${t("menu.bl")}: ${address?.block}, ${t("menu.str")}: ${
                      address?.street
                    })`;

                    const office = `${addressLabel} (${t("menu.comp")}: ${
                      address?.company_name
                    }, ${t("menu.bl")}: ${address?.block}, ${t(
                      "menu.floor"
                    )}: ${address?.floor})`;

                    const apartment = `${addressLabel} (${t("menu.apr")}: ${
                      address?.apartment_no
                    }, ${t("menu.bl")}: ${address?.block}, ${t("menu.str")}: ${
                      address?.street
                    })`;

                    return (
                      <Dropdown.Item
                        onClick={() => {
                          onSelectLocatiom(address);
                          localStorage.setItem("selectCurrent", false);
                        }}
                        style={{
                          textTransform: "capitalize",
                        }}
                        key={address.id}
                        disabled={disableMenu}
                        className={retrunLanguage(i18n) && "name-ar"}
                      >
                        <strong>
                          {address?.address_type === "house"
                            ? house?.length > 30
                              ? truncateString(house, 30)
                              : house
                            : address?.address_type === "office"
                            ? truncateString(office, 30)
                            : truncateString(apartment, 30)}
                        </strong>
                        <span
                          style={{
                            fontSize: 13,
                            lineHeight: "13px",
                            display: "block",
                          }}
                        >
                          {address?.delivery_charge_area_name_en}
                        </span>
                        {/* <span
                          style={{
                            fontSize: 13,
                          }}
                        >
                          {selected !== null &&
                            currentLocation !== null &&
                            parseFloat(selected?.lat) ===
                              parseFloat(currentLocation?.lat) &&
                            parseFloat(selected?.lng) ===
                              parseFloat(currentLocation?.lng) &&
                            "(current location)"}
                        </span> */}
                      </Dropdown.Item>
                    );
                  })
                ) : (
                  <Dropdown.Item
                    style={{
                      textTransform: "capitalize",
                    }}
                    disabled={true}
                    className={retrunLanguage(i18n) && "name-ar"}
                  >
                    {t("menu.noAddress")}
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <span
            style={{
              borderLeft: "1.5px solid #C3D8D4",
              height: "30px",
              marginTop: "8px",
            }}
          ></span>

          <ScheduleOrder
            branchHours={branchHours}
            setRestaurantOpen={setRestaurantOpen}
            orderTime={delivTime}
            setOpenDatePicker={setOpenDatePicker}
            openDatePicker={openDatePicker}
            orderType={"delivery"}
            selected={selected}
          />
        </div>

        <ScheduleDialog
          restaurantOpen={restaurantOpen}
          setRestaurantOpen={setRestaurantOpen}
          setOpenSchedulePopup={setOpenDatePicker}
          menuCard={true}
          isCheckingAuto={isCheckingAuto}
        />

        <DialogComponent
          notDeliver={selectAddressNotDeliver}
          setNotDeliver={setSelectAddressNotDeliver}
          fromDetail={true}
          restaurantName={restaurantDetail.page_title_en}
          resturantId={restaurantDetail.id}
          location={location}
          menuCard={true}
          afterSelect={true}
        />

        <RemoveFromCart
          clearCartModal={clearCartModal}
          setClearCartModal={setClearCartModal}
          textToShow={t("branchChangeWraning", { ns: "dialog" })}
          clearFunction={clearFunction}
          itemsAfterRemove={itemsAfterRemove}
          removingItem={removingItem}
        />
      </StyledOuterContainer>
    </>
  );
};

export default DeliveryDetails;
